<template>
	<div>
		<div class="modal-header">
		    <div class="modal-title">
		        <a-icon type="medicine-box" style="margin-right:10px" />
		        <span>{{l('Referral_of_issues')}}</span>
		    </div>
		</div>
		<div>
		    <a-config-provider :locale="zh_CN">
				<a-row>
				    <a-col :span="24" style="text-align: center;">
						<a-select showSearch :filterOption="filterOption" style="width: 200px;" dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false" :placeholder="l('Please_select_the_person_responsible')" allow-clear>
							<a-select-option v-for="item in workProcessData" :key="item.id" @click="workProcessClassChange" :title="item.name">
							  {{ item.realName }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
		    <a-button @click="close">
		        {{l('Cancel')}}
		    </a-button>
		    <a-button :type="'primary'" @click="save">
		        {{l('Save')}}
		    </a-button>
		</div>
	</div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { IssueServiceProxy} from '../../../../shared/service-proxies';
	
	let _this;
	export default {
		name: 'transfer',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
		    this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.getData();
		},
		data() {
		    return {
				zh_CN,
		        isLook: false,//是否是查看
				workProcessData: [],
				user: undefined,
		    }
		},
		methods: {
			getData() {
				console.log(this.issueid);
				console.log(this.userid);
				this.isLoading = true;
				this.IssueServiceProxy.getAllEmp(
					this.userid
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					console.log(res);
					this.workProcessData = res;
				})
			},
			workProcessClassChange(value){
				console.log(value.key);
				this.user = value.key;
			},
			filterOption(input, option){
			    return (
			        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			    );
			},
			save() {
				if (this.user==undefined) {
				    abp.message.info(this.l("Please_select_the_person_responsible"));
				    return;
				}
				this.IssueServiceProxy.transfer(
					this.issueid,
					this.user
				).finally(() => {
				    this.close()
				}).then(res => {
				    this.success(true)
				})
			},
			
		}
	}
</script>

<style>
</style>

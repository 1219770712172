<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px"/>
                <span>{{l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-spin :spinning="isLoading">
                    <a-row>
                    <a-col :span="11">
                        <a-row>

                            <a-col :span="24">
                                <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"
                                             :label="l('audit.name')">
                                    <a-input v-model="TaskName"
                                             :placeholder="l('please_enter_Title')"
                                             style="width: 100%;"></a-input>
                                </a-form-item>
                            </a-col>

                            <a-col :span="24">
                                <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"
                                             :label="l('Label')">
                                    <a-select showSearch :filterOption="filterOption"
                                              style="width: 100%;text-align: left;"
                                              dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                              :placeholder="l('Please_select_a_label')"
                                              allow-clear
                                              mode="multiple"
                                              @change="AuditTaskLabelChange"
                                    >
                                        <a-select-option v-for="item in AuditTaskLabelList" :key="item.itemDetailCode"
                                                         :title="item.itemDetailName">
                                            {{ item.itemDetailName }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :span="5">
                                <a-form-item :label="l('audit.inspectorname')">
                                    <!-- <a-select showSearch :filterOption="filterOption"
                                              style="width: 100%;text-align: left;"
                                              dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                              :placeholder="l('please_select_reviewer')"
                                              allow-clear
                                              mode="multiple"
                                              @change="LPAUserChange"
                                    >
                                        <a-select-option v-for="item in AllLPAUser" :key="item.id"
                                                         :title="item.realName">
                                            {{ item.realName }}
                                        </a-select-option>
                                    </a-select> -->
                                </a-form-item>
                            </a-col>
							<a-col :span="17">
								<div style="width: 100%;height: 64.99px;">
									<div style="width: 100%;height: 40px;position: relative;">
										<div style="width: 100%;height: 32px;border: 1px solid #d9d9d9;border-radius: 4px;position: absolute;top: 4px;" @click="respNameSelect">
											<div style="width: 100%;line-height: 30px;color: #c6c6c6;padding-left: 11px;overflow: auto;white-space: nowrap;" :placeholder="l('Please_select_the_person_responsible')" class="input">
												<template v-for="(tag, index) in respTags">
													<a-tooltip :key="tag" :title="tag">
														<a-tag :key="tag" :closable="true" @close="() => resHandleClose(tag)">
														    {{ tag }}
														</a-tag>
													</a-tooltip>
												</template>
											</div>
										</div>
									</div>
								</div>
							</a-col>

                            <a-col :span="12">
                                <a-form-item required :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }"
                                             :label="l('AuditCycle')">
                                    <a-input-number v-model="AuditCycle"></a-input-number>
                                </a-form-item>
                            </a-col>

                            <a-col :span="12">
                                <a-form-item required :wrapper-col="{ span: 14 }">
                                    <a-select showSearch :filterOption="filterOption"
                                              style="width: 100%;text-align: left;"
                                              dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                              :placeholder="l('select_Unit')"
                                              allow-clear
                                              @change="AuditCycleUnitChange"
                                              default-value="day"
                                    >
                                        <a-select-option key="minute" :title="l('minute')">
                                            {{l("minute")}}
                                        </a-select-option>
                                        <a-select-option key="hour" :title="l('hour')">
                                            {{l("hour")}}
                                        </a-select-option>
                                        <a-select-option key="day" :title="l('day')">
                                            {{l("day")}}
                                        </a-select-option>
                                        <a-select-option key="week" :title="l('week')">
                                            {{l("week")}}
                                        </a-select-option>
                                        <a-select-option key="month" :title="l('month')">
                                            {{l("month")}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>


                            <a-col :span="12" v-if="isrange">
                                <a-form-item required :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }"
                                             :label="l('TimeRange')">
                                    <a-time-picker :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                                                   :placeholder="l('TimeRangeStart')" @change="onChangeRangeStart"/>
                                </a-form-item>
                            </a-col>

                            <a-col :span="11" offset="1" v-if="isrange">
                                <a-form-item :wrapper-col="{ span: 14 }">
                                    <a-time-picker :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                                                   :placeholder="l('TimeRangeEnd')" @change="onChangeRangeEnd"/>
                                </a-form-item>
                            </a-col>

                            <a-col :span="24">
                                <a-form-item required :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"
                                             :label="l('audit.plandate')">
                                    <a-range-picker @change="timeChange"
                                                    :placeholder="[l('audit.planstart'), l('audit.planend')]"
                                                    style=" width: 100%;text-align: left;"/>
                                </a-form-item>
                            </a-col>

                            <a-col :span="24" v-if="isweek">
                                <a-form-item required :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"
                                             :label="l('WeekRange')">
                                    <a-checkbox-group @change="onChangeWeek" v-model="WeekRangeList">
                                        <a-row>
                                            <a-col :span="6">
                                                <a-checkbox value="1">
                                                    {{l("Monday")}}
                                                </a-checkbox>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-checkbox value="2">
                                                    {{l("Tuesday")}}
                                                </a-checkbox>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-checkbox value="3">
                                                    {{l("Wednesday")}}
                                                </a-checkbox>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-checkbox value="4">
                                                    {{l("Thursday")}}
                                                </a-checkbox>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-checkbox value="5">
                                                    {{l("Friday")}}
                                                </a-checkbox>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-checkbox value="6">
                                                    {{l("Saturday")}}
                                                </a-checkbox>
                                            </a-col>
                                            <a-col :span="6">
                                                <a-checkbox value="0">
                                                    {{l("Sunday")}}
                                                </a-checkbox>
                                            </a-col>
                                        </a-row>
                                    </a-checkbox-group>
                                </a-form-item>
                            </a-col>


                            <a-col :span="12">
                                <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }" :label="l('Start_Early')">
                                    <a-input-number v-model="EarlyStart" :formatter="value => `${value}` + l('day')" :parser="value => value.replace(l('day'), '')">
									</a-input-number>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }"
                                             :label="l('Delayed_Completion')">
                                    <a-input-number v-model="DelayedFinish" :formatter="value => `${value}` + l('day')" :parser="value => value.replace(l('day'), '')"></a-input-number>
                                </a-form-item>
                            </a-col>

<!--                            <a-col :span="24">-->
<!--                                <a-row>-->
<!--                                    <a-col :span="24">-->
<!--                                        <a-form-item style="text-align: left; margin-left: 10%;">-->
<!--                                            <a-checkbox v-model="IsNoExist" />  {{l('Prohibitcreatingduplicatetasks')}}-->
<!--                                        </a-form-item>-->
<!--                                    </a-col>-->
<!--                                </a-row>-->
<!--                            </a-col>-->


<!--                            <a-col :span="24">-->
<!--                                <a-row>-->
<!--                                    <a-col :span="24">-->
<!--                                        <a-form-item  required :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }"-->
<!--                                                      :label="l('创建任务方式')" style="text-align: left; margin-left: 5%;">-->
<!--                                            <a-radio-group v-model="PollOrRepeat" @change="PollOrRepeatchange" style="margin-left: 25px;">-->
<!--                                                <a-radio :value="1">-->
<!--                                                    {{this.l('轮询')}}-->
<!--                                                </a-radio>-->
<!--                                                <a-radio :value="2">-->
<!--                                                    {{this.l('重复')}}-->
<!--                                                </a-radio>-->
<!--                                                &lt;!&ndash;                                        <a-radio :value="3">&ndash;&gt;-->
<!--                                                &lt;!&ndash;                                            自由点检&ndash;&gt;-->
<!--                                                &lt;!&ndash;                                        </a-radio>&ndash;&gt;-->
<!--                                            </a-radio-group>-->
<!--                                        </a-form-item>-->
<!--                                    </a-col>-->
<!--                                </a-row>-->
<!--                            </a-col>-->



                            <a-col :span="24">
                                <a-row>
                                    <a-col :span="24" >
                                        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"
                                                     :label="l('audit.remark')">
                                            <a-textarea :placeholder="l('please_enter_remark')" v-model="Remark" :rows="4"/>
                                        </a-form-item>

                                    </a-col>
                                </a-row>
                            </a-col>

                        </a-row>
                    </a-col>

                    <a-col :span="13">
                        <a-row>
                            <a-col :span="24" style="margin-top: 10px;">
                                <a-form-item required :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
                                             :label="l('CreateMode')" style="text-align: left;margin-left: 20px;">
                                    <a-radio-group v-model="Modevalue" @change="ModeChange" style="margin-left: 25px;">
                                        <a-radio :value="1">
                                            {{this.l('AuditPointTemplate')}}
                                        </a-radio>
                                        <a-radio :value="2">
                                            {{this.l('AuditItemTemplate')}}
                                        </a-radio>
<!--                                        <a-radio :value="3">-->
<!--                                            自由点检-->
<!--                                        </a-radio>-->
                                    </a-radio-group>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                    <!--                    审核点模板-->
                    <a-col :span="13" v-if="PointTemplate">
                        <a-row>
                            <a-col :span="24">


                                <a-row>
                                    <a-col :span="24">
                                        <a-row>
                                            <a-col :span="24">
                                                <a-form-item style="text-align: left; margin-left: 10%;">
                                                    <a-checkbox v-model="IsNoExist" />  {{l('Prohibitcreatingduplicatetasks')}}
                                                </a-form-item>
                                            </a-col>
                                        </a-row>
                                    </a-col>
                                </a-row>


                                <a-row>
                                    <a-col :span="24">
                                        <a-row>
                                            <a-col :span="24">
                                                <a-form-item  required :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }"
                                                              :label="l('How _the_task_is_created')" style="text-align: left; margin-left: 5%;">
                                                    <a-radio-group v-model="PollOrRepeat" @change="PollOrRepeatchange" style="margin-left: 25px;">
                                                        <a-radio :value="1">
                                                            {{this.l('Polling')}}
                                                        </a-radio>
                                                        <a-radio :value="2">
                                                            {{this.l('Repeat')}}
                                                        </a-radio>
                                                        <!--                                        <a-radio :value="3">-->
                                                        <!--                                            自由点检-->
                                                        <!--                                        </a-radio>-->
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-col>
                                        </a-row>
                                    </a-col>
                                </a-row>


                                <a-row>
                                    <a-col :span="5">
                                        <a-form-item required :label="l('audit.audittemplatename')"
                                                     style="text-align: right;">
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="12">
                                        <a-button @click="selectaudittemplate" style="margin-left: 10%;">
                                            <a-icon type="plus"/>
                                            {{l('Create')}}
                                        </a-button>
<!--                                        <a-button @click="xiuxi" style="margin-left: 20%;">-->
<!--                                            <a-icon type="plus"/>-->
<!--                                            {{l('rest')}}-->
<!--                                        </a-button>-->
                                    </a-col>
                                </a-row>
                            </a-col>
                            <!--                            <a-col :span="24">-->
                            <!--                                <span style="margin-left: 26px;margin-bottom: 20px;display: block; color: red;">* 添加多个方案，可以在计划期间内按顺序轮循</span>-->
                            <!--                            </a-col>-->
                            <a-col :span="24">
                                <div style="width: 200px;height: 200px;border: 1px solid lightgray;margin-left: 30px;overflow: scroll;">
                                    <draggable v-model="tags">
                                        <transition-group>
                                            <template v-for="(tag, index) in tags">
                                                <!--                                        <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">-->
                                                <!--                                            <a-tag :key="tag" :closable="index !== 0" @close="() => handleClose(tag)" style="width: 300px;height: 30px;font-size: 1em;line-height: 30px;margin-left: 30px;">-->
                                                <!--                                                {{ `${tag.slice(0, 20)}...` }}-->
                                                <!--                                            </a-tag>-->
                                                <!--                                        </a-tooltip>-->
                                                <a-tag :key="tag.name" checked="checked" :closable="true"
                                                       @close="() => handleClose(tag)"
                                                       style="width: auto;height: 30px;font-size: 15px;line-height: 30px;margin-top: 5px;margin-left: 5px;">
                                                    {{ tag.name }}
                                                </a-tag>
                                            </template>
                                        </transition-group>
                                    </draggable>
                                </div>
                            </a-col>
                        </a-row>
                    </a-col>
                    <!--                    审核项模板-->
                    <a-col :span="13" v-if="ItemTemplate">
                        <a-row>
                            <a-col :span="24">
                                <a-row>
                                    <a-col :span="6">
                                        <a-form-item required :label="l('audit.audittemplatename')"
                                                     style="text-align: center;">
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="12">
                                        <a-input @click="selectItemTemplate" v-model="entityItemTemplateName"
                                                 :placeholder="l('please_select_AuditItemTemplate')"
                                                 style="width: 100%;margin-bottom: 20px;"></a-input>
                                    </a-col>
                                </a-row>
                            </a-col>
                            <!--                            <a-col :span="24">-->
                            <!--                                <span style="margin-left: 26px;margin-bottom: 20px;display: block; color: red;">* 添加多个方案，可以在计划期间内按顺序轮循</span>-->
                            <!--                            </a-col>-->
                            <a-col :span="24">
                                <a-form-item required :label="l('please_select_AuditPoint')"
                                             style="text-align: left;margin:-20px 0px -1px 25px;">
                                </a-form-item>
                                <div style="width: 400px;height: 350px;margin-left: 30px;border: 1px solid lightgray;margin-left: 30px;overflow: auto;">
                                    <a-tree checkable
                                            v-model="checkedKeys"
                                            :tree-data="tableData"
                                            :expanded-keys="expandedKeys"
                                            :auto-expand-parent="autoExpandParent"
                                            :selected-keys="selectedKeys"
                                            @expand="onExpand"
                                            @select="onSelect"
                                            default-expand-all
                                            v-if="!spinning"
                                    >
                                        <a-spin :spinning="spinning"></a-spin>
                                    </a-tree>

                                </div>
                            </a-col>
                        </a-row>
                    </a-col>
                    <!--                    自由点检-->
                    <a-col :span="13" v-if="FreeTemplate">
                        <a-row>
                            <a-col :span="24">
                                <a-row>
                                    <a-col :span="5">
                                        <a-form-item required :label="l('audit.audittemplatename')"
                                                     style="text-align: right;">
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="12">
                                        <a-button @click="selectaudittemplate" style="margin-left: 10%;">
                                            <a-icon type="plus"/>
                                            {{l('Create')}}
                                        </a-button>
                                        <a-button @click="xiuxi" style="margin-left: 20%;">
                                            <a-icon type="plus"/>
                                            {{l('rest')}}
                                        </a-button>
                                    </a-col>
                                </a-row>
                            </a-col>
                            <!--                            <a-col :span="24">-->
                            <!--                                <span style="margin-left: 26px;margin-bottom: 20px;display: block; color: red;">* 添加多个方案，可以在计划期间内按顺序轮循</span>-->
                            <!--                            </a-col>-->
                            <a-col :span="24">
                                <div style="width: 200px;height: 200px;border: 1px solid lightgray;margin-left: 30px;overflow: scroll;">
                                    <draggable v-model="tags">
                                        <transition-group>
                                            <template v-for="(tag, index) in tags">
                                                <!--                                        <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">-->
                                                <!--                                            <a-tag :key="tag" :closable="index !== 0" @close="() => handleClose(tag)" style="width: 300px;height: 30px;font-size: 1em;line-height: 30px;margin-left: 30px;">-->
                                                <!--                                                {{ `${tag.slice(0, 20)}...` }}-->
                                                <!--                                            </a-tag>-->
                                                <!--                                        </a-tooltip>-->
                                                <a-tag :key="tag.name" checked="checked" :closable="true"
                                                       @close="() => handleClose(tag)"
                                                       style="width: auto;height: 30px;font-size: 15px;line-height: 30px;margin-top: 5px;margin-left: 5px;">
                                                    {{ tag.name }}
                                                </a-tag>
                                            </template>
                                        </transition-group>
                                    </draggable>
                                </div>
                            </a-col>
                        </a-row>
                    </a-col>




                </a-row>
                </a-spin>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>

    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {
        AuditItemGroupServiceProxy, AuditPointServiceProxy,
        AuditTaskServiceProxy,
        CreateAuditTaskDto
    } from '../../../../shared/service-proxies';
    import CreatErrorCode from "../../audit-items/new-item/new-item";
    import selectmodel from "../audit-template-model/audit-template-model";
    import {ModalHelper} from '@/shared/helpers';
    import moment from "moment";
    import draggable from "vuedraggable";
    import selectitemmodel from "../audit-item-template-model/audit-item-template-model";
    import {Dic, fileDownloadService} from "../../../../shared/utils";
	import PersonnelSelection from "../../Personnel-selection/Personnel-selection.vue";

    let _this;

    export default {
        name: "create-audit-task",
        mixins: [ModalComponentBase],
        components: {
            draggable,
        },
        created() {
            _this = this;
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
            this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
            try {
                this.isLoading = true;
                this.AuditTaskLabelList = await Dic.getInstance().getDicAsync('AuditTaskLabel');
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }

            this.getData();
            this.getTreeData();
            this.getAllLPAUser();
            this.PointTemplate = true;
            console.log(this.AuditCycleUnit)
        },
        data() {
            return {
                zh_CN,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                entity: [],
                pId: undefined,
                treeDataList: [],
                treeReplaceFields: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                itemid: undefined,
                isLoading: false,
                AllLPAUser: [],

                tags: [],
                inputVisible: false,
                inputValue: '',

                UserIds: [],
				respName: undefined,
                StartDateTime: undefined,
                EndDateTime: undefined,
                AuditTemplate: [],
                AuditTem: [],
                AuditTemplateList: [],
                AuditItem: [],
                AuditPoint: [],
                AuditCycle: 1,
                AuditCycleUnit: 'day',
                TimeRangeStart: undefined,
                TimeRangeEnd: undefined,
                WeekRangeList: ["1", "2", "3", "4", "5"],
                WeekRange: '',
                Remark: "",

                selectedRowKey: [], // 选择的数据Id
                selectedRowList: [],
                selectedRow: [],//选择的整行数据

                newAuditTemplate: [],

                isrange: false,
                isweek: true,
                IsNoExist: true,
                Modevalue: 1,

                PointTemplate: false,
                ItemTemplate: false,
                FreeTemplate: false,

                entityItemTemplateId: undefined,
                entityItemTemplateName:undefined,
                tableData: [],
                columns: [
                    {title: this.l('Name'),width: 220,dataIndex: 'name',align: 'left',ellipsis: true},
                    {title: this.l('auditpoint.enable'),width: 60,dataIndex: 'enable',align: 'center',scopedSlots: {customRender: "enable"}},
                ],

                expandedKeys: [],
                autoExpandParent: true,
                checkedKeys: [],
                selectedKeys: [],
                TaskName:"",
                spinning: true,

                PollOrRepeat:1,
				isclick: true,

                AuditTaskLabelList:[],
                AuditTaskLabel:undefined,

                EarlyStart: 0,
                DelayedFinish: 0,
				respTags: [],
				respData: [],
            }
        },

        watch: {
            checkedKeys(val) {
                console.log('onCheck', val);
                console.log(this.checkedKeys)
                console.log(this.expandedKeys)
                console.log(this.selectedKeys)
            },
        },

        methods: {

            moment,


            PollOrRepeatchange(e){
                console.log(this.PollOrRepeat)
            },


            onExpand(expandedKeys) {
                console.log('onExpand', expandedKeys);
                this.expandedKeys = expandedKeys;
                this.autoExpandParent = false;
            },
            onCheck(checkedKeys) {
                console.log('onCheck', checkedKeys);
                this.checkedKeys = checkedKeys;
            },
            onSelect(selectedKeys, info) {
                console.log('onSelect', info);
                this.selectedKeys = selectedKeys;
            },

            selectItemTemplate() {
                ModalHelper.create(selectitemmodel, {}, {width: 1000}).subscribe((res) => {
                    if (res) {
                        console.log(res);
                        this.entityItemTemplateId = res[0].id;
                        this.entityItemTemplateName = res[0].name;
                    }
                });
            },

            // //行选择
            // customRow: (record) => {
            //     return {
            //         on: {
            //             click: () => {
            //                 _this.onSelect(record)
            //             }
            //         }
            //     }
            //
            // },
            // //多选
            // SelectAll(record, rows, crows) {
            //     if (record) {
            //         //更改表格数据勾选状态
            //         this.tableData.map(item => {
            //             item.checked = true;
            //         });
            //         rows.forEach((item, index) => {
            //             //判断是否存在
            //             let u = this.selectedRowKey.find(t => t == item.id);
            //             if (!u) {
            //                 this.selectedRowKey.push(item.id);
            //                 this.selectedRowList.push(item);
            //             }
            //         });
            //     } else {
            //         //更改表格数据勾选状态
            //         this.tableData.forEach((item, index) => {
            //             item.checked = false;
            //             this.selectedRowKey = [];
            //             this.selectedRowList = [];
            //         });
            //     }
            // },
            // // 重写复选框点击事件(不可删除)
            // onSelect(record) {
            //     //debugger;
            //     console.log(record);
            //     record.checked = !record.checked;
            //     if (record.checked) {
            //         this.selectedRowKey.push(record.id);
            //         this.selectedRowList.push(record);
            //         this.name = record.name;
            //         this.number = record.code;
            //         this.respName = record.respName;
            //     } else {
            //         this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
            //         this.selectedRowList = _.difference(_this.selectedRowList, [record]);
            //     }
            // },

            ModeChange(e) {
                if (e.target.value == 1) {
                    this.PointTemplate = true;
                    this.ItemTemplate = false;
                    this.FreeTemplate = false;
                    this.IsNoExist = true;
                    this.PollOrRepeat = 1;
                } else if (e.target.value == 2) {
                    this.PointTemplate = false;
                    this.ItemTemplate = true;
                    this.FreeTemplate = false;
                    this.IsNoExist = false;
                    this.PollOrRepeat = 2;
                } else if (e.target.value == 3) {
                    this.PointTemplate = false;
                    this.ItemTemplate = false;
                    this.FreeTemplate = true;
                }
            },


            AuditCycleUnitChange(value) {

                console.log(value)

                if (value == "minute" || value == "hour") {
                    this.isrange = true;
                    this.isweek = true;
                } else if (value == "day" || value == "week") {
                    this.isrange = false;
                    this.isweek = true;
                } else {
                    this.isrange = false;
                    this.isweek = false;
                }

                this.AuditCycleUnit = value;

            },

            onChangeWeek(checkedValues) {
                this.WeekRangeList = checkedValues;


                console.log(this.WeekRange)
            },

            onChangeRangeStart(time, timeString) {
                console.log(timeString);
                this.TimeRangeStart = moment("2000-1-1 " + timeString);
                console.log(this.TimeRangeStart)
            },

            onChangeRangeEnd(time, timeString) {
                console.log(timeString);
                this.TimeRangeEnd = moment("2000-1-1 " + timeString);
                console.log(this.TimeRangeEnd)
            },

            getData() {
                this.selectedRowKey = [];
                this.selectedRowList = [];
                this.isLoading = true;
                this.AuditPointServiceProxy.getAllData().finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.tableData = res;
                    this.spinning = false;
                })
            },

            handleClose(removedTag) {
                const tags = this.tags.filter(tag => tag !== removedTag);
                console.log(tags);
                this.tags = tags;
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(function () {
                    this.$refs.input.focus();
                });
            },

            handleInputChange(e) {
                this.inputValue = e.target.value;
            },

            handleInputConfirm() {
                const inputValue = this.inputValue;
                let tags = this.tags;
                if (inputValue && tags.indexOf(inputValue) === -1) {
                    tags = [...tags, inputValue];
                }
                console.log(tags);
                Object.assign(this, {
                    tags,
                    inputVisible: false,
                    inputValue: '',
                });
            },


            xiuxi() {

                return;
                if (this.AuditTemplate.length <= 0) {
                    this.AuditTemplate.push(0);
                    this.tags.push("空方案");
                } else {
                    if (Math.min(...this.AuditTemplate) <= 0) {
                        let min = Math.min(...this.AuditTemplate) // 1
                        this.AuditTemplate.push(min - 1);
                        this.tags.push("空方案" + Math.abs(min - 1));
                    } else {
                        this.AuditTemplate.push(0);
                        this.tags.push("空方案");
                    }
                }

            },


            selectaudittemplate() {
                ModalHelper.create(selectmodel, {}, {width: 1000}).subscribe(res => {
                    console.log(res)
                    if (res) {
                        res.forEach((item, index) => {
                            // this.AuditTemplate = item.id;
                            this.AuditTemplate.push(item.id);
                        });
                        // this.AuditTemplate = res.id;
                        this.tags = res;
                        console.log(this.AuditTemplate)
                        // this.AuditTemplateList = res.name;
                        // let ss =[];
                        // this.AuditTemplateList.forEach(item=>{
                        //     ss.push(item.name)
                        // })
                        // this.tags = ss;
                    }
                })
            },


            unique(arr) {
                return Array.from(new Set(arr))
            },


            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

            timeChange(date, dateString) {
                console.log(date, dateString);
                this.StartDateTime = date[0];
                this.EndDateTime = date[1];
                console.log(this.StartDateTime)
                console.log(this.EndDateTime)
            },

            getAllLPAUser() {
                this.isLoading = true;
                this.AuditTaskServiceProxy.getAllLPAUsers(
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res);
                        this.AllLPAUser = res;
                    })
            },

            LPAUserChange(value) {
                console.log(value);
                this.UserIds = value;
                console.log(this.UserIds)
            },

            AuditTaskLabelChange(value) {
                console.log(value);
                this.AuditTaskLabel = value;
                console.log(this.AuditTaskLabel)
            },

            getTreeData() {
                this.isLoading = true;
                this.AuditItemGroupServiceProxy.getPaged(
                    undefined,
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res);
                        this.treeDataList = JSON.parse(JSON.stringify(res));
                    })
            },
            save() {
                console.log(this.AuditTaskLabel)
                if (!this.StartDateTime) {
                    abp.message.info('请选择时间');
                    return;
                }

                if (this.PointTemplate){
                    if (this.AuditTemplate.length <= 0) {
                        abp.message.info('请选择审核模板');
                        return;
                    }
                }

                if (this.AuditCycle <= 0) {
                    abp.message.info('请输入一个合适的数字，不支持负数和0');
                    return;
                }

                if (this.AuditCycleUnit == undefined) {
                    abp.message.info('请选择审核周期单位');
                    return;
                }
                if (this.isrange) {
                    if (!this.TimeRangeStart || !this.TimeRangeEnd) {
                        abp.message.info('请选择时间区间');
                        return;
                    }
                }
                if (this.isweek) {
                    if (this.WeekRangeList.length <= 0) {
                        abp.message.info('请至少选择一天');
                        return;
                    }
                }
                if (this.ItemTemplate){
                    if(this.entityItemTemplateId == undefined){
                        abp.message.info('请选择审核模板');
                        return;
                    }
                    if(this.checkedKeys.length<=0){
                        abp.message.info('请至少选择一个审核点');
                        return;
                    }
                }
                this.tags.forEach((item, index) => {
                    this.AuditTem.push(item.id);
                });
                let entity = new CreateAuditTaskDto();
                entity.name = this.TaskName;
                if (_this.AuditTaskLabel == undefined || _this.AuditTaskLabel == "") {
                    entity.auditTaskLabel = [];
                }
                else{
                    entity.auditTaskLabel = _this.AuditTaskLabel;
                }
                
                entity.userIds = this.UserIds;
                entity.startDateTime = moment(this.StartDateTime);
                entity.endDateTime = moment(this.EndDateTime);
                if (this.PointTemplate){
                    entity.auditTemplate = this.AuditTem;
                    entity.auditPointIdList = [];
                }else if (this.ItemTemplate){
                    entity.auditTemplate = [this.entityItemTemplateId];
                    entity.auditPointIdList = this.checkedKeys;
                }else if (this.FreeTemplate){
                    // entity.auditTemplate = [this.entityItemTemplateId];
                }
                entity.auditItem = this.AuditItem;
                entity.auditPoint = this.AuditPoint;
                entity.auditCycle = this.AuditCycle;
                entity.auditCycleUnit = this.AuditCycleUnit;
                entity.timeRangeStart = this.TimeRangeStart;
                entity.timeRangeEnd = this.TimeRangeEnd;
                entity.createMode = this.Modevalue;
                entity.isNoExist = this.IsNoExist;
                entity.pollOrRepeat = this.PollOrRepeat;
                entity.earlyStart = this.EarlyStart;
                entity.delayedFinish = this.DelayedFinish;

                this.WeekRange = '';
                for (let i = 0; i < this.WeekRangeList.length; i++) {
                    if (this.WeekRangeList.length - i == 1) {
                        this.WeekRange += this.WeekRangeList[i];
                    } else {
                        this.WeekRange += this.WeekRangeList[i] + ",";
                    }
                }
                entity.weekRange = this.WeekRange;
                entity.remark = this.Remark;
                console.log(entity);
                this.isLoading = true;
				if(this.isclick) {
					this.isclick = false;
					this.AuditTaskServiceProxy.createAuditTask(
					    entity
					).finally(() => {
					    this.isLoading = false;
					}).then(res => {
						this.isclick = true;
					    this.success(true)
					})
					
				}
            },
			
			respNameSelect() {
				ModalHelper.create(PersonnelSelection, {
					respId: this.UserIds,
					isSelect: false,
					userType: 2
				}, {
					width: 800
				}).subscribe(res => {
					if (res) {
						this.respData = res;
						this.respTags = [];
						this.UserIds = [];
						for(let i in res) {
							this.respTags.push(res[i].realName)
							this.UserIds.push(res[i].userId)
						}
					}
				})
			},
			
			resHandleClose(removedTag) {
				const tags = this.respTags.filter(tag => tag !== removedTag);
				this.respTags = tags;
				for(let i in this.respData) {
					if(removedTag == this.respData[i].realName) {
						let ids = this.UserIds.filter(id => id !== this.respData[i].userId);
						this.UserIds = ids;
					}
				}
			},
        }
    }
</script>

<style scoped>
    /* .ant-row .ant-form-item{
        margin-bottom: 10px;
    } */
	.input:empty::before {
		content: attr(placeholder);
	}
</style>

<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{l('Edit')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">

                <a-row>
                    <a-col :span="11">
                        <a-row>
                            <a-col :span="24">
                                <a-form-item  :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"  :label="l('audit.inspectorname')">
									<a-input v-model="inspectorName"
									         :placeholder="l('Please_select_the_person_responsible')" allow-clear
									         style="width: 100%;" @click="respNameSelect"></a-input>
                                </a-form-item>
                            </a-col>
                            <a-col :span="24">
                                <a-form-item required :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }" :label="l('audit.plandate')">
                                    <a-range-picker @change="timeChange" :default-value="[moment(planStart.format('YYYY-MM-DD'),'YYYY/MM/DD'), moment(planEnd.format('YYYY-MM-DD'),'YYYY/MM/DD')]" :placeholder="[l('audit.planstart'), l('audit.planend')]" style=" width: 100%;text-align: left;"/>
                                </a-form-item>
                            </a-col>
							<a-col :span="24">
								<a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"
								             :label="l('Label')">
								    <a-select showSearch :filterOption="filterOption"
								              style="width: 100%;text-align: left;"
								              dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
								              :placeholder="l('Please_select_a_label')"
								              allow-clear
								              mode="multiple"
								              @change="AuditTaskLabelChange"
											  v-model="AuditTaskLabel"
								    >
								        <a-select-option v-for="item in AuditTaskLabelList" :key="item.itemDetailCode"
								                         :title="item.itemDetailName">
								            {{ item.itemDetailName }}
								        </a-select-option>
								    </a-select>
								</a-form-item>
							</a-col>

                            <a-col :span="12">
                                <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }"
                                             :label="l('Start_Early')">
                                    <a-input-number v-model="EarlyStart" :formatter="value => `${value}` + l('day')" :parser="value => value.replace(l('day'), '')"></a-input-number>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }"
                                             :label="l('Delayed_Completion')">
                                    <a-input-number v-model="DelayedFinish" :formatter="value => `${value}` + l('day')" :parser="value => value.replace(l('day'), '')"></a-input-number>
                                </a-form-item>
                            </a-col>

                            <a-col :span="24">
                                <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"
                                             :label="l('TemplateBarCode')">
                                    <a-input v-model="TemplateBarCode"></a-input>
                                </a-form-item>
                            </a-col>

                            <a-col :span="24">
                                <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }"
                                             :label="l('AssitField')">
                                    <a-input v-model="AssitField"></a-input>
                                </a-form-item>
                            </a-col>

                        </a-row>
                    </a-col>
                    <a-col :span="13">
                        <a-row>
                            <a-col :span="24">
                                <a-row>
                                    <a-col :span="5">
                                        <a-form-item required :label="l('audit.audittemplatename')" style="text-align: right;">
                                        </a-form-item>
                                    </a-col>
<!--                                    <a-col :span="12">-->
<!--                                        <a-button @click="selectaudittemplate" style="margin-left: 10%;">-->
<!--                                            <a-icon type="plus"/>-->
<!--                                            {{l('Create')}}-->
<!--                                        </a-button>-->
<!--                                        <a-button @click="xiuxi" style="margin-left: 20%;">-->
<!--                                            <a-icon type="plus"/>-->
<!--                                            {{l('rest')}}-->
<!--                                        </a-button>-->
<!--                                    </a-col>-->
                                </a-row>
                            </a-col>
                            <!--                            <a-col :span="24">-->
                            <!--                                <span style="margin-left: 26px;margin-bottom: 20px;display: block; color: red;">* 添加多个方案，可以在计划期间内按顺序轮循</span>-->
                            <!--                            </a-col>-->
                            <a-col :span="24">
                                <div style="width: 200px;height: 200px;border: 1px solid lightgray;margin-left: 30px;overflow: scroll;">
                                    <template v-for="(tag, index) in tags">
                                        <!--                                        <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">-->
                                        <!--                                            <a-tag :key="tag" :closable="index !== 0" @close="() => handleClose(tag)" style="width: 300px;height: 30px;font-size: 1em;line-height: 30px;margin-left: 30px;">-->
                                        <!--                                                {{ `${tag.slice(0, 20)}...` }}-->
                                        <!--                                            </a-tag>-->
                                        <!--                                        </a-tooltip>-->
                                        <a-tag :key="tag" checked="checked" @close="() => handleClose(tag)"  style="width: auto;height: 30px;font-size: 1.2em;line-height: 30px;margin-top: 5px;margin-left: 5px;">
                                            {{ tag }}
                                        </a-tag>
                                    </template>
                                    <!--                                    <a-input-->
                                    <!--                                            v-if="inputVisible"-->
                                    <!--                                            ref="input"-->
                                    <!--                                            type="text"-->
                                    <!--                                            size="small"-->
                                    <!--                                            :style="{ width: '78px' }"-->
                                    <!--                                            :value="inputValue"-->
                                    <!--                                            @change="handleInputChange"-->
                                    <!--                                            @blur="handleInputConfirm"-->
                                    <!--                                            @keyup.enter="handleInputConfirm"-->
                                    <!--                                    />-->
                                    <!--                                    <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">-->
                                    <!--                                        <a-icon type="plus" /> New Tag-->
                                    <!--                                    </a-tag>-->
                                </div>
                            </a-col>
                        </a-row>
                    </a-col>


                    <a-col :span="24">
                        <a-row>
                            <a-col :span="24" style="margin-top: 10px;">
                                <a-form-item  :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }"  :label="l('audit.remark')">
                                    <a-textarea :placeholder="l('please_enter_remark')" v-model="Remark" :rows="4" />
                                </a-form-item>

                            </a-col>
                        </a-row>
                    </a-col>

                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>

    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {
        AuditItemGroupServiceProxy,
        AuditTaskListDto,
        AuditTaskServiceProxy,
        CreateAuditTaskDto,
        AuditTaskEditDto,
    } from '../../../../shared/service-proxies';
    import CreatErrorCode from "../../audit-items/new-item/new-item";
    import selectmodel from "../audit-template-model/audit-template-model";
    import { ModalHelper } from '@/shared/helpers';
    import moment from "moment";
	import {Dic} from "../../../../shared/utils";
	import PersonnelSelection from "../../Personnel-selection/Personnel-selection.vue";

    let _this;

    export default {
        name: "update-audit-task",
        mixins: [ModalComponentBase],
        components: {

        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
            this.isLoading = true;
            this.AuditTaskServiceProxy.getForEdit(
                this.id
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                console.log(res);
                this.inspectorId = res.inspectorId;
				this.inspectorName = res.inspectorName;
                this.planStart = res.planStart;
                this.planEnd = res.planEnd.add(-1, 'days');
                this.EarlyStart = res.earlyStart;
                this.DelayedFinish = res.delayedFinish;
                this.TemplateBarCode = res.templateBarCode;
                this.AssitField = res.assitField;
                
				if(res.auditTaskLabel===null) {
				}else {
					this.AuditTaskLabel = res.auditTaskLabel;
				}
                this.AuditTemplate.push(res.auditTemplateId)
                this.tags.push(res.auditTemplateName)
            })
			try {
			    this.loading = true;
				this.AuditTaskLabelList = await Dic.getInstance().getDicAsync('AuditTaskLabel');
			} catch (e) {
			    
			} finally {
			    this.loading = false;
			}
            this.getData();
            this.getAllLPAUser();
        },
        data() {
            return {
                zh_CN,
                id:undefined,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                entity: [],
                pId: undefined,
                treeDataList: [],
                treeReplaceFields: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                itemid: undefined,
                isLoading:false,
                AllLPAUser:[],

                tags: [],
                inputVisible: false,
                inputValue: '',

                UserIds:[],
                StartDateTime:[],
                EndDateTime:[],
                AuditTemplate:[],
                AuditItem:[],
                AuditPoint:[],
                Remark:"",

                newAuditTemplate:[],
                inspectorId:undefined,
				inspectorName:undefined,
                planStart:undefined,
                planEnd:undefined,
				isclick: true,
				AuditTaskLabel: [],
				AuditTaskLabelList: [],
				loading: false,
                EarlyStart:undefined,
                DelayedFinish:undefined,
            }
        },
        methods: {

            moment,

            getData(){

            },

            handleClose(removedTag) {
                const tags = this.tags.filter(tag => tag !== removedTag);
                console.log(tags);
                this.tags = tags;
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(function() {
                    this.$refs.input.focus();
                });
            },

            handleInputChange(e) {
                this.inputValue = e.target.value;
            },

            handleInputConfirm() {
                const inputValue = this.inputValue;
                let tags = this.tags;
                if (inputValue && tags.indexOf(inputValue) === -1) {
                    tags = [...tags, inputValue];
                }
                console.log(tags);
                Object.assign(this, {
                    tags,
                    inputVisible: false,
                    inputValue: '',
                });
            },

			AuditTaskLabelChange(value) {
			    this.AuditTaskLabel = value;
			},

            xiuxi(){

                return;
                if(this.AuditTemplate.length <= 0){
                    this.AuditTemplate.push(0);
                    this.tags.push("空方案");
                }
                else{
                    if(Math.min(...this.AuditTemplate) <= 0){
                        let min = Math.min(...this.AuditTemplate) // 1
                        this.AuditTemplate.push(min-1);
                        this.tags.push("空方案" + Math.abs(min-1));
                    }
                    else{
                        this.AuditTemplate.push(0);
                        this.tags.push("空方案");
                    }
                }

            },


            selectaudittemplate(){
                ModalHelper.create(selectmodel, {
                }).subscribe(res => {
                    console.log(res)
                    if(res){
                        this.AuditTemplate.push(res.id)
                        this.tags.push(res.name);
                        this.AuditTemplate = this.unique(this.AuditTemplate)
                        this.tags = this.unique(this.tags)
                    }
                })
            },


            unique (arr) {
                return Array.from(new Set(arr))
            },


            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

            timeChange(date, dateString) {
                console.log(date, dateString);
                this.StartDateTime = [];
                this.EndDateTime = [];
                this.StartDateTime.push(date[0]);
                this.EndDateTime.push(date[1])

                this.planStart = date[0];
                this.planEnd = date[1];

                console.log(this.StartDateTime)
                console.log(this.EndDateTime)
            },

            getAllLPAUser(){
                this.isLoading = true;
                this.AuditTaskServiceProxy.getAllLPAUsers(
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res);
                        this.AllLPAUser = res;
                    })
            },

            LPAUserChange(value){
                console.log(value);
                this.UserIds = value;
                console.log(this.UserIds)
            },

            getTreeData() {
                this.isLoading = true;
                this.AuditItemGroupServiceProxy.getPaged(
                    undefined,
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res);
                        this.treeDataList = JSON.parse(JSON.stringify(res));
                    })
            },
            save() {
                if (!this.planStart) {
                    abp.message.info('请选择时间');
                    return;
                }

                let entity = new AuditTaskListDto();
                entity.id = this.id;
                entity.inspectorId = this.inspectorId;
                entity.planStart = this.planStart;
                entity.planEnd = this.planEnd.add(1, 'days');
                entity.remark = this.Remark;
				entity.auditTaskLabel = this.AuditTaskLabel.toString();
                entity.earlyStart = this.EarlyStart;
                entity.delayedFinish = this.DelayedFinish;
                entity.templateBarCode = this.TemplateBarCode;
                entity.assitField = this.AssitField;
				if(this.isclick) {
					this.isclick = false;
					this.AuditTaskServiceProxy.update(
					    entity
					).finally(() => {
					
					}).then(res => {
						this.isclick = true;
					    this.success(true);
					})
				}

            },
			
			respNameSelect() {
				ModalHelper.create(PersonnelSelection, {
					respId: this.inspectorId,
					isSelect: true,
					userType: 2
				}, {
					width: 800
				}).subscribe(res => {
					if (res) {
						this.inspectorName = res[0].realName;
						this.inspectorId = res[0].userId;
					}
				})
			},

        }
    }
</script>

<style>
    .ant-form-item{
        margin-bottom: 5px;
    }
</style>

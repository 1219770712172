<template>
    <div :class="{'reuse-tab_pad':isPad}" class="reuse-tab ad-rt fixed">
        <a-tabs :activeKey="pos" :animated="false" type="line" size="small">
            <template v-for="item of list">
                <a-tab-pane :key="item.path">
                    <template slot="tab">
                        <span @click="to($event, item)" class="name" v-if="item.title == 270">
                            {{l('Spot_Check')}}
                        </span>
						<span @click="to($event, item)" class="name" v-else>
						    {{item.moduleId == null?item.displayTitle:l(item.moduleId)}}
						</span>
                        <a-icon @click="close($event, item)"
                                class="reuse-tab__op"
                                type="close"
                                v-if="item.closable">
                        </a-icon>
                    </template>
                </a-tab-pane>
            </template>
        </a-tabs>
    </div>
</template>

<script>
    import { AppComponentBase } from "@/shared/component-base";
    import {layoutService, menuService, reuseTabService} from '@/shared/common';
    import bus from '@/shared/bus/bus.js'
    import abpService from "@/shared/abp/abp.service";

    export default {
        name: "reuse-tab",
        mixins: [AppComponentBase],
        data() {
            return {
                listVal: [],
                posVal: null,
                change$: null,
                changePos$: null,
                tabitem:{
                    name: "dashboard",
                    closable: false,
                    path: "/app/main/dashboard",
                    displayTitle: "工作台",
                    title: "工作台",
                    i18n: null,
                    reuse: false,
                    moduleId:undefined
                }
            }
        },
        computed: {
            list() {
                return this.listVal;
            },
            pos() {
                return this.posVal;
            },
            isPad() {
                return layoutService.data.isPad;
            }
        },
        created() {
            this.change$ = reuseTabService.change.subscribe((tabs) => {
                this.listVal = tabs;
                this.tabitem.displayTitle=this.l("workbench");
                var items=this.listVal.filter(item=>item.name=="dashboard");
                if(items.length<=0){
                    this.listVal=[this.tabitem,...this.listVal];
                }
                else {

                    //工作台固定第一位
                    this.listVal=[this.tabitem,...this.listVal.filter(item=>item.name!="dashboard")];
                }
            });
            this.changePos$ = reuseTabService.changePos.subscribe((to) => {
                this.posVal = to;
            });
        },
        methods: {
            to(event, item) {
                // 模块Id写入cookie
                abpService.setModuleKey(item.moduleId);
                this.$router.push({path: item.path});
                //路由跳转
                // reuseTabService.to(item);
                let key= item.moduleId;
                //获取当前菜单最大父级菜单
                let headerMenus=reuseTabService.getMaxParantMenus(key);
                if(headerMenus){
                    //调动头部菜单的点击方法，模拟点击
                    bus.$emit("headerMenuClick",headerMenus);
                }

            },
            close(event, item) {
                reuseTabService.remove(item);
            }
        },
        destroyed() {
            if (this.change$) {
                this.change$.unsubscribe();
            }
            if (this.changePos$) {
                this.changePos$.unsubscribe();
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./style/index.less";
</style>

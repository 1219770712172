<template>
    <a-spin :spinning="isLoading">
    <div>
    <div class="modal-header">
        <div class="modal-title">
            <a-icon type="medicine-box" style="margin-right:10px" />
            <span>{{iscreate?l('Create_the_issue'):l('Edit')}}</span>
        </div>
    </div>

    <div>
        <a-config-provider :locale="zh_CN">
            <a-row>
                <a-row style="margin-bottom: 20px;">
					<a-col style="margin-bottom: -30px;" v-if="auditItem">
					    <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21, style:'text-align: left'}" :label="l('AppAuditItem')" class="body">
							<P>{{auditItem}}</P>
						</a-form-item>
					</a-col>
                    <a-col :span="3">
                        <a-form-item :label="l('issue.description')" class="body">
                        </a-form-item>
                    </a-col>
                    <a-col :span="21">
                        <a-textarea v-model="description" style="height: 60px;width:100%;"></a-textarea>
                    </a-col>
                </a-row>


                <a-row>
                    <a-col :span="3">
                        <a-form-item :label="l('issuetype')" class="body">
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-tree-select
                                v-model="issueTypeIdList"
                                multiple
                                style="width:200px"
                                :tree-data="issueTypelist"
                                :replace-fields="issueType"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
                                :dropdownMatchSelectWidth=false
                                :placeholder="l('Issue_tip_select')"
                                allow-clear
                                tree-default-expand-all
                        >
                        </a-tree-select>
                    </a-col>

                    <a-col :span="4" style="margin-left: 10px">
                        <a-form-item :label="l('auditpoint.respid')" class="body">
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-input v-model="respName"
                                 :placeholder="l('Please_select_the_person_responsible')" allow-clear
                                 style="width: 200px;" @click="respNameSelect"></a-input>
                    </a-col>

                <a-col :span="4" style="margin-left: 10px">
                    <a-form-item :label="l('Discoverer')" class="body">
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-select showSearch
                              style="width: 200px;text-align: left;"
                              dropdownClassName="drop-down-Style"
                              :dropdownMatchSelectWidth="false"
                              v-model="discovererId"
                              optionFilterProp="label"
                              :disabled='true'
                    >
                        <a-select-option v-for="item in discovererlist" :key="item.id" :label="item.realName">
                            {{ item.realName }}
                        </a-select-option>
                    </a-select>
                </a-col>



                </a-row>

                <a-row>

                <a-col :span="3">
                    <a-form-item :label="l('220')" class="body">
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-tree-select
                            v-model="pId"
                            style="width: 200px"
                            :tree-data="auditPointIdlist"
                            :replace-fields="auditPoint"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
                            :dropdownMatchSelectWidth=false
                            :placeholder="l('select_auditpoint')"
                            allow-clear
                            tree-default-expand-all
                    >
                    </a-tree-select>
                </a-col>

                    <a-col :span="4" style="margin-left: 10px">
                        <a-form-item :label="l('issueprogress.Progress')" class="body">
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-select showSearch
                                  style="width: 200px;text-align: left;"
                                  dropdownClassName="drop-down-Style"
                                  :dropdownMatchSelectWidth="false"
                                  v-model="issueProgress"
                                  :placeholder="l('Pleaseselectaprogress')"
                                  allow-clear
                                  optionFilterProp="label"
                        >
                            <a-select-option v-for="item in issueProgresslist" :key="item.progress" :label="item.name">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col :span="4" style="margin-left: 10px">
                        <a-form-item :label="l('Submissiontime')" class="body">
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-date-picker :disabled=true style="width: 200px;text-align: left;" v-model="discoveryTime" :placeholder="l('select_date')"/>
                    </a-col>

                </a-row>

                <a-row>

                <a-col :span="3">
                    <a-form-item :label="l('Completetime')" class="body">
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-date-picker style="width: 200px;text-align: left;" v-model="closeTime"  :placeholder="l('select_date')"/>
                </a-col>

                    <a-col :span="4" style="margin-left: 10px">
                        <a-form-item :label="l('audit.planstart')" class="body">
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-date-picker style="width: 200px;text-align: left;" v-model="planStart" :placeholder="l('select_date')"/>
                    </a-col>

                    <a-col :span="4" style="margin-left: 10px">
                        <a-form-item :label="l('PlanCompleteTime')" class="body">
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-date-picker style="width: 200px;text-align: left;" v-model="planEnd" :placeholder="l('select_date')"/>
                    </a-col>


                </a-row>


            </a-row>

        </a-config-provider>
    </div>

        <div>
            <a-tabs type="card">
                <!-- <a-tab-pane key="1" :tab="l('issue.description')">
                    <a-textarea v-model="description" style="height: 80px;width:100%;"></a-textarea>
                </a-tab-pane> -->
                <a-tab-pane key="2" :tab="l('issue.discoverypicture')">
                    <a-upload

                            style="width: 900px;word-wrap:break-word"
                            :action="uploadPictureUrl"
                            list-type="picture-card"
                            :fileList="discoveryPicturelist"
                            accept="image/*"
                            :headers="picUploadHeaders"
                            :beforeUpload="beforeUpload"
                            :remove="removePic"
                            :multiple="false"
                            @preview="handlePreview"
                            @change="handleChange"
							:disabled="IsEnableUploadPicture"
                    >
                        <div >
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">
                                {{l('Upload_an_image')}}
                            </div>
                        </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                        <img alt="example" style="width: 100%" :src="previewImage"/>
                    </a-modal>
                </a-tab-pane>
                <a-tab-pane key="3" :tab="l('issue.improvepicture')">
                    <a-upload

                            style="width: 900px;word-wrap:break-word"
                            :action="uploadPictureUrl"
                            list-type="picture-card"
                            :fileList="improvePicturelist"
                            accept="image/*"
                            :headers="picUploadHeaders"
                            :beforeUpload="beforeUpload"
                            :remove="removePic"
                            @preview="handlePreview2"
                            @change="handleChange2"
							:disabled="IsEnableUploadPicture"
                    >
                        <div v-if="improvePicturelist.length < 10">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">
                                {{l('Upload_an_image')}}
                            </div>
                        </div>
                    </a-upload>
                    <a-modal :visible="previewVisible2" :footer="null" @cancel="handleCancel2">
                        <img alt="example" style="width: 100%" :src="previewImage2"/>
                    </a-modal>
                </a-tab-pane>
                <a-tab-pane key="4" :tab="l('issue.improvenote')">
                    <a-textarea v-model="improveNote" style="height: 80px;width:100%;"></a-textarea>
                </a-tab-pane>
                <a-tab-pane key="5" :tab="l('issue.correctiveNote')">
                    <a-textarea v-model="correctiveNote" style="height: 80px;width:100%;"></a-textarea>
                </a-tab-pane>
                <a-tab-pane key="6" :tab="l('Remark')">
                    <a-textarea v-model="remark" style="height: 80px;width:100%;"></a-textarea>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div class="modal-footer">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
    </a-spin>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {
        AuditPointServiceProxy,
        EmployeeServiceProxy,
        IssueTypeServiceProxy,
        IssueProgressServiceProxy, IssueServiceProxy,IssueEditDto,
        SystemConfigServiceProxy
    } from '../../../../shared/service-proxies';
    import {AppConsts} from "../../../../abpPro/AppConsts";
    import ImageUtils from "../../../../shared/utils/image-utils";
	import { ModalHelper } from '@/shared/helpers';
	import PersonnelSelection from "../../Personnel-selection/Personnel-selection.vue";
    import moment from "moment";

let _this;
    export default {
        name: "updataIssue",
        mixins: [ModalComponentBase],
        components: {

        },

        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
            this.IssueTypeServiceProxy = new IssueTypeServiceProxy(this.$apiUrl, this.$api);
            this._EmployeeServiceProxy=new EmployeeServiceProxy(this.$apiUrl, this.$api);
            this.IssueProgressServiceProxy=new IssueProgressServiceProxy(this.$apiUrl, this.$api);
            this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
            this.SystemConfigServiceProxy = new SystemConfigServiceProxy(this.$apiUrl, this.$api);
        },


        mounted() {
            _this = this;
			this.getIsEnableUploadPicture();
            this.getPictureQuality();
            this.getuser();
            this.getissueTreeData();
            this.getissuerprogess();
            this.getAuditPointTreeData();
            if(this.id!=null){
                this.getEditData();
            }


        },

        data() {
            return {
                zh_CN,
                isKanban:false,
                isLoading:false,
                id:undefined,
                pId:undefined,
                previewVisible: false,
                previewVisible2: false,
                //发现人
                discovererId:undefined,
                discovererlist:[],
                //责任人
                respId:undefined,
				respName:undefined,
                resplist:[],
                //问题分类
                issueTypeIdList:[],
                issueTypelist:[],
                issueType: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                //审核点
                auditPointId:undefined,
                auditPointIdlist:[],
                auditPoint:{
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                //审核进度
                issueProgress:undefined,
                issueProgresslist:[],
                //提交时间
                discoveryTime:undefined,
                //完成时间
                closeTime:undefined,
                //计划开始时间
                planStart:undefined,
                //计划结束时间
                planEnd:undefined,
                description:undefined,
                // 上传的接口地址
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/TempFileUpLoad",
                //图片的请求头信息
                picUploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken(),
                    type: "photo"
                },
                previewImage: '',
                previewImage2: '',
                //发现时图片
                discoveryPicture:'',
                discoveryPicturelist:[],
                improvePicture:'',
                improvePicturelist:[],

                //改善建议
                improveNote:undefined,
                //改善措施
                correctiveNote:undefined,
                remark:undefined,
                entity:new IssueEditDto(),
                imgPath:`${AppConsts.OSSRequestUrl}/File/LPA/${abp.session.tenantId}/Issue/`,

                iscreate:undefined,
				isclick: true,
				auditItem: undefined,

                PictureQuality:undefined,
				IsEnableUploadPicture: true,
				isClose:undefined
            }
        },

        methods:{
            save(){
                this.entity.id=this.id;
                this.entity.issueTypeIdList=this.issueTypeIdList;
				if(this.isClose) {
					this.issueProgress = this.isClose;
				}
                this.entity.issueProgress=this.issueProgress;
                this.entity.description=this.description;
                this.entity.respId=this.respId;
				this.entity.respName=this.respName;
                this.entity.auditPointId=this.pId;
                this.entity.planStart=this.planStart;
                this.entity.planEnd=this.planEnd;
                this.entity.closeTime=this.closeTime;
                this.entity.discoveryPicture=this.discoveryPicture;
                this.entity.improvePicture=this.improvePicture;
				if(this.discoveryPicture[this.discoveryPicture.length-1] == ",") {
					this.entity.discoveryPicture=this.discoveryPicture.substr(0,this.discoveryPicture.length - 1); 
				}
				if(this.improvePicture[this.improvePicture.length-1] == ",") {
					this.entity.improvePicture=this.improvePicture.substr(0,this.improvePicture.length - 1); 
				}
                this.entity.improveNote=this.improveNote;
                this.entity.correctiveNote=this.correctiveNote;
                this.entity.remark=this.remark;
				
                var resultlist={
                    "IssueEditDto":this.entity
                };
				if(this.isclick) {
					this.isclick = false;
					this.IssueServiceProxy.handleIssue(
					    resultlist
					)
					    .finally(() => {
					        this.close()
					    })
					    .then((res) => {
					        this.success(true)
							this.isclick = true;
					    })
				}
            },
			
			//获取是图片上传是否禁用
			getIsEnableUploadPicture() {
				this.isLoading = true;
				this.SystemConfigServiceProxy.getIsEnableUploadPicture(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.IsEnableUploadPicture = !res;
				})
			},

            getPictureQuality() {
			    this.isLoading = true;
			    this.SystemConfigServiceProxy.getPictureQuality()
                .finally(() => {
			        this.isLoading = false;
			    }).then(res => {
					this.PictureQuality = res;
			    })
			},

            //获取用户
            getuser(){
                this._EmployeeServiceProxy.getAllEmp().finally(()=>{

                })  .then((res)=>{
                    res.forEach(item=>{
                        this.discovererlist.push(item);
                        this.resplist.push(item);
                    });

                })
            },
            //获取问题分类
            getissueTreeData() {
                this.isLoading = true;
                this.IssueTypeServiceProxy.getPaged(
                    undefined,
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {console.log(res);
                        this.issueTypelist=JSON.parse(JSON.stringify(res));
                        /*res.forEach(item=>{
                            this.issueTypelist.push(item);
                        });*/
                    })
            },

            //获取审核点
            getAuditPointTreeData() {
                this.isLoading = true;
                this.AuditPointServiceProxy.getAllData(
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        this.auditPointIdlist=JSON.parse(JSON.stringify(res));
                        /*res.forEach(item=>{
                            this.auditPointIdlist.push(item);
                        });*/
                    })
            },

            //获取问题进度
            getissuerprogess(){
                this.isLoading = true;
                this.IssueProgressServiceProxy.getIssueStatusList(
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        res.forEach(item=>{
                            if(item.progress!=100&&this.isKanban==false){
                                this.issueProgresslist.push(item);
                            }
                            if(this.isKanban){
                                this.issueProgresslist.push(item);
                            }
                        });

                        let process = [];
                        this.issueProgresslist.forEach(item=>{
                            process.push(item.progress)
                        })
                        if (process.indexOf(_this.issueProgress) == -1){
							this.isClose = this.issueProgress;
							this.issueProgress = undefined;
                        }

                    })
            },

            //获取编辑数据
            getEditData(){
                this.isLoading = true;
                this.IssueServiceProxy.getForEdit(this.id)
                .finally(()=>{
                    this.isLoading = false;
                })
                .then((res)=>{
                  console.log(res);
                   this.discovererId=res.discovererId;
                   this.respId=res.respId;
				   this.respName=res.respName;
                   this.issueTypeIdList=res.issueTypeIdList;
                   this.pId=res.auditPointId;
                   this.issueProgress=res.issueProgress;
                   this.discoveryTime=res.discoveryTime?moment(res.discoveryTime):undefined;
                   this.closeTime=res.closeTime?moment(res.closeTime):undefined;
                   this.planStart=res.planStart?moment(res.planStart):undefined;
                   this.planEnd=res.planEnd?moment(res.planEnd):undefined;
                   this.description=res.description;
				   if (res.discoveryPicture != "" && res.discoveryPicture != null) {
						if (res.discoveryPicture.charAt(res.discoveryPicture.length-1) != ",") {
							this.discoveryPicture=res.discoveryPicture + ',';
						} else{
							this.discoveryPicture=res.discoveryPicture;
						}
				   }
				   if (res.improvePicture != "" && res.improvePicture != null) {
				   		if (res.improvePicture.charAt(res.improvePicture.length-1) != ",") {
				   			this.improvePicture=res.improvePicture + ',';
				   		} else{
				   			this.improvePicture=res.improvePicture;
				   		}
				   }
                  this.improveNote=res.improveNote;
                  this.correctiveNote=res.correctiveNote;
                  this.remark=res.remark;
                   var str1=res.discoveryPicture == null?[]:res.discoveryPicture.split(',');
                   str1.forEach(item=>{
                       if(item!=null&&item!=""){
                           this.discoveryPicturelist.push(
                               {
                                   uid: item,
                                   name: item,
                                   status: 'done',
                                   url: res.realPath+'/'+item,
                               }
                           )
                       }
                   });


                   var str2=res.improvePicture == null?[]:res.improvePicture.split(',');
                    str2.forEach(item=>{
                        if(item!=null&&item!=""){
                            this.improvePicturelist.push(
                                {
                                    uid: item,
                                    name: item,
                                    status: 'done',
                                    url: res.realPath+'/'+item,
                                }
                            )
                        }
                    });



                });


            },





            //图片上传
            beforeUpload(file) {
                // const isJPG =
                //     file.type === "image/jpeg" ||
                //     file.type === "image/png" ||
                //     file.type === "image/gif" ||
                //     file.type === "image/bmp";
                // if (!isJPG) {
                //     abp.message.error(this.l("请上传正确格式的图片文件"));
                // }
                // const isLtXM = file.size / 1024 / 1024 < 5;
                // if (!isLtXM) {
                //     abp.message.error(
                //         this.l(
                //             "ProfilePicture_Warn_SizeLimit",
                //             2
                //         )
                //     );
                // }
                // return isJPG && isLtXM;

                return new Promise(async (resolve, reject) => {
                    const isJPG =
                        file.type === "image/jpeg" ||
                        file.type === "image/png" ||
                        file.type === "image/gif" ||
                        file.type === "image/bmp";
                    if (!isJPG) {
                        abp.message.error(this.l("请上传正确格式的图片文件"));
                    }
                    const isLtXM = file.size / 1024 / 1024 < 5;
                    // if (!isLtXM) {
                    //     abp.message.error(
                    //         this.l(
                    //             "ProfilePicture_Warn_SizeLimit",
                    //             2
                    //         )
                    //     );
                    // }
                    var newPictureQuality = this.PictureQuality.split(",")[1];
					var size = this.PictureQuality.split(",")[0];
                    const newFile = await this.compressImg(file,newPictureQuality,size)
                    resolve(newFile)
                })
            },

            //删除
            removePic(file) {
                return new Promise((resolve, reject) => {
                    this.$confirm({
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk() {
                            resolve(true)

                        },
                        onCancel() {
                            reject(false)
                        },
                    })

                })

            },
            //预览
            handleCancel() {
                this.previewVisible = false;
            },
            handleCancel2() {
                this.previewVisible2 = false;
            },
            async handlePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await ImageUtils.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            async handlePreview2(file) {
                if (!file.url && !file.preview) {
                    file.preview = await ImageUtils.getBase64(file.originFileObj);
                }
                this.previewImage2 = file.url || file.preview;
                this.previewVisible2 = true;
            },

            handleChange({file, fileList}) {console.log(file,'file');
                if (file.status === 'done') {
                    if(this.discoveryPicture==''){
                        this.discoveryPicture=file.response.result.fileName+',';
                    }else {
                        this.discoveryPicture=this.discoveryPicture+file.response.result.fileName+',';
                    }
                }

                if (file.status === 'removed'){
                    if(file.response==undefined){
                        var str=file.name+',';
                        this.discoveryPicture=this.discoveryPicture.split(str).join('');
                    }else {
                        var str=file.response.result.fileName+',';
                        this.discoveryPicture=this.discoveryPicture.split(str).join('');
                    }
                }
				let index = fileList.length;
				if(fileList[index-1].status == "done") {
					fileList[index-1].uid = fileList[index-1];
				}
                this.discoveryPicturelist = fileList;
            },

            handleChange2({file, fileList}) {console.log(file,"file");
                if (file.status === 'done') {
                    if(this.improvePicture==''){
                        this.improvePicture=file.response.result.fileName+',';
                    }else {
                        this.improvePicture=this.improvePicture+file.response.result.fileName+',';
                    }
                }

                if (file.status === 'removed'){
                    if(file.response==undefined){
                        var str=file.name+',';
                        this.improvePicture=this.improvePicture.split(str).join('');
                    }else {
                        var str=file.response.result.fileName+',';
                        this.improvePicture=this.improvePicture.split(str).join('');
                    }
                }
				let index = fileList.length;
				if(fileList[index-1].status == "done") {
					fileList[index-1].uid = fileList[index-1];
				}
                this.improvePicturelist = fileList;
            },

			respNameSelect() {
				ModalHelper.create(PersonnelSelection, {
					respId: this.respId,
					isSelect: true,
					userType: 1
				}, {
					width: 800
				}).subscribe(res => {
					if (res) {
						this.respName = res[0].realName;
						this.respId = res[0].userId;
					}
				})
			},

        }


    }
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{l('ViewDetails')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>
                    <a-col :span="24">
                        <a-col :span="3" class="problemview">
                            <p>{{l('issue.description')}}:</p>
                        </a-col>
                        <a-col :span="21">
                            <p style="margin-left: 10px;">{{description}}</p>
                        </a-col>
                    </a-col>
                    <a-col :span="24">
                        <a-col :span="3" class="problemview">
                            <p>{{l('audititem.name')}}:</p>
                        </a-col>
                        <a-col :span="21">
                            <p style="margin-left: 10px;">{{AuditItemName}}</p>
                        </a-col>
                    </a-col>
                    <a-col :span="24">
                        <a-col :span="3" class="problemview">
                            <p>{{l('Discoverer')}}:</p>
                        </a-col>
                        <a-col :span="5">
                            <p style="margin-left: 10px;">{{discoverer}}</p>
                        </a-col>
                        <a-col :span="3" class="problemview">
                            <p>{{l('auditpoint.respid')}}:</p>
                        </a-col>
                        <a-col :span="5">
                            <p style="margin-left: 10px;">{{respName}}</p>
                        </a-col>
                        <a-col :span="3" class="problemview">
                            <p>{{l('Submissiontime')}}:</p>
                        </a-col>
                        <a-col :span="5">
                            <p style="margin-left: 10px;">{{discoveryTime}}</p>
                        </a-col>
                    </a-col>
                    <a-col :span="24">
                        <a-col :span="3" class="problemview">
                            <p>{{l('issuetype')}}:</p>
                        </a-col>
                        <a-col :span="5">
                            <p style="margin-left: 10px;">{{issueTypeListName}}</p>
                        </a-col>
                        <a-col :span="3" class="problemview">
                            <p>{{l('issueprogress.Progress')}}:</p>
                        </a-col>
                        <a-col :span="5">
                            <p style="margin-left: 10px;">{{issueProgressName}}</p>
                        </a-col>
                        <a-col :span="3" class="problemview">
                            <p>{{l('220')}}:</p>
                        </a-col>
                        <a-col :span="5">
                            <p style="margin-left: 10px;">{{auditPoint}}</p>
                        </a-col>
                    </a-col>
                    <a-col :span="24">
                    </a-col>
                    <a-col :span="24">
                        <a-col :span="3" class="problemview">
                            <p>{{l('audit.planstart')}}:</p>
                        </a-col>
                        <a-col :span="5">
                            <p style="margin-left: 10px;">{{planStart}}</p>
                        </a-col>
                        <a-col :span="3" class="problemview">
                            <p>{{l('PlanCompleteTime')}}:</p>
                        </a-col>
                        <a-col :span="5">
                            <p style="margin-left: 10px;">{{planEnd}}</p>
                        </a-col>
                        <a-col :span="3" class="problemview">
                            <p>{{l('Completetime')}}:</p>
                        </a-col>
                        <a-col :span="5">
                            <p style="margin-left: 10px;">{{closeTime}}</p>
                        </a-col>
                    </a-col>
                </a-row>
            </a-config-provider>
        </div>
        <div>
            <a-tabs type="card">
                <!-- <a-tab-pane key="1" :tab="l('issue.description')">
						<a-textarea v-model="description" style="height: 80px;width:100%;" readonly="readonly"></a-textarea>
				</a-tab-pane> -->
                <a-tab-pane key="1" :tab="l('issue.discoverypicture')">
                    <div style="min-height: 117px;" class="images" v-vuer="{title: false}"
                        v-if='discoveryPicture != null && discoveryPicture != ""'>
                        <img :src="item" :key="item" style="width: 100px;height: 100px;margin-right: 10px;"
                            v-if="item!=poth" v-for="item in photo" @click="show()" />
                    </div>
                    <div class="prompt" v-else>
                        <div class="Image">
                            <img src="../../../../../public/img/icons/img.png" style="width: 30px;height: 30px;" />
                            <br />
                            <span>{{l('NotUploaded')}}</span>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" :tab="l('issue.improvepicture')">
                    <div style="min-height: 117px;" class="images1" v-vuer="{title: false}"
                        v-if='improvePicture != null && improvePicture != ""'>
                        <img :src="item" :key="item" style="width: 100px;height: 100px;margin-right: 10px;"
                            v-if="item!=poth" v-for="item in Photo" @click="Show()" />
                    </div>
                    <div class="prompt" v-else>
                        <div class="Image">
                            <img src="../../../../../public/img/icons/img.png" style="width: 30px;height: 30px;" />
                            <br />
                            <span>{{l('NotUploaded')}}</span>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" :tab="l('issue.improvenote')">
                    <div style="min-height: 117px;">
                        <a-textarea v-model="improveNote" style="height: 110px;width:100%;"
                            readonly="readonly"></a-textarea>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="4" :tab="l('issue.correctiveNote')">
                    <div style="min-height: 117px;">
                        <a-textarea v-model="correctiveNote" style="height: 110px;width:100%;"
                            readonly="readonly"></a-textarea>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="5" :tab="l('Remark')">
                    <div style="min-height: 117px;">
                        <a-textarea v-model="remark" style="height: 110px;width:100%;" readonly="readonly"></a-textarea>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="6" :tab="l('problemrecord')">
                    <a-row>
                        <a-col :offset="3">
                            <a-timeline style="margin-top: 15px;">
                                <a-timeline-item v-for="item in history" :key="item">
                                    <span v-if="item.isCreate">{{item.operaterRealName == null ? "" :
                                        item.operaterRealName}} {{item.operateTime == null ?"":item.operateTime}}
                                        创建了问题</span>
                                    <span v-else>
                                        <span v-if="item.changeKey == 'DiscoveryPicture'">
                                            <span
                                                 v-if="picture != '删除了' && picture != '上传了' && picture != '调整了'">
                                                {{item.operaterRealName == null ? "" : item.operaterRealName}}
                                                {{item.operateTime == null ?"":item.operateTime}}
                                                {{splitPictures(item.afterValue,item.beforeValue)[splitPictures(item.afterValue,item.beforeValue).length-1]}}
                                                {{l(item.changeKeyCode)}}
                                                <img :src="realPath + '/' + picture" :key="index" v-for="picture,index in splitPictures(item.afterValue,item.beforeValue)"
                                                    style="width: 20px;height: 20px;margin-right: 10px;"
                                                    v-if="splitPictures(item.afterValue,item.beforeValue)[splitPictures(item.afterValue,item.beforeValue).length-1] != '删除了' && picture.length != 3 && !picture" @click="show()" />
                                            </span>
                                        </span>
                                        <span v-else-if="item.changeKey == 'ImprovePicture'">
                                            <span
                                                 v-if="picture != '删除了' && picture != '上传了' && picture != '调整了'">
                                                {{item.operaterRealName == null ? "" : item.operaterRealName}}
                                                {{item.operateTime == null ?"":item.operateTime}}
                                                {{splitPictures(item.afterValue,item.beforeValue)[splitPictures(item.afterValue,item.beforeValue).length-1]}}
                                                {{l(item.changeKeyCode)}}
                                                <img :src="realPath + '/' + picture" :key="index" v-for="picture,index in splitPictures(item.afterValue,item.beforeValue)"
                                                    style="width: 20px;height: 20px;margin-right: 10px;"
                                                    v-if="splitPictures(item.afterValue,item.beforeValue)[splitPictures(item.afterValue,item.beforeValue).length-1] != '删除了' && picture.length != 3 && !picture" @click="show()" />
                                            </span>
                                        </span>
                                        <span v-else>
                                            {{item.operaterRealName == null ? "" : item.operaterRealName}}
                                            {{item.operateTime == null ?"":item.operateTime}} 将
                                            {{l(item.changeKeyCode)}}从{{item.beforeValue == null?"":item.beforeValue}}
                                            调整为{{item.afterValue == null?"":item.afterValue}}。
                                        </span>
                                    </span>
                                </a-timeline-item>
                            </a-timeline>
                        </a-col>
                    </a-row>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-row>
                <a-col :span="18" style="text-align: left;">
                    <div style="width: 20px;float: left;margin-top: 5px;margin-right: 10px;">
                        <a @click.stop="concern()" v-if="issueSubscribeId == null">
                            <a-icon type="star" style="color: #f4ca3a;font-size: 20px;" id="concern"
                                :title="issueSubscribeId?'关注':'未关注'" />
                        </a>
                        <a @click.stop="concern()" v-if="issueSubscribeId != null">
                            <a-icon type="star" style="color: #f4ca3a;font-size: 20px;" theme="filled" id="concern"
                                :title="issueSubscribeId?'关注':'未关注'" />
                        </a>
                    </div>

                    <div style="width: 200px;margin-top: 5px;">
                        <a @click="getNumber()">
                            <font id="number">{{ number }}</font>
                        </a>
                        <font>{{ l('peoplepaidattentiontothisissue') }}</font>
                    </div>
                </a-col>

                <a-col :span="6">
                    <a-button @click="Close()" type="primary"
                        v-if="issueProgress != 100&&isGranted('problem_management_close')">
                        {{l('Close_the_issue')}}
                    </a-button>
                    <a-button @click="Edit()" type="primary"
                        v-if="issueProgress != 100&&isGranted('problem_management_edit')">
                        {{l('Edit')}}
                    </a-button>
                    <a-button @click="close">
                        {{l('Cancel')}}
                    </a-button>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import { IssueServiceProxy } from '../../../../shared/service-proxies';
    import { AppConsts } from "../../../../abpPro/AppConsts";
    import ImageUtils from "../../../../shared/utils/image-utils";
    import CreatErrorCode from "../UpdataIssue/updataIssue";
    import ViewNumber from "../ViewNumber/ViewNumber";
    import closeissue from "../close-issue/close-issue.vue"
    import { ModalHelper } from '@/shared/helpers';
    import moment from "moment";
    import 'viewerjs/dist/viewer.css'
    import Vuer from 'v-viewer'
    import Vue from 'vue'
    Vue.use(Vuer, { name: 'vuer' })

    let _this;
    export default {
        name: 'view',
        mixins: [ModalComponentBase],
        components: {

        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getData();
        },
        data() {
            return {
                zh_CN,
                isLook: false,//是否是查看
                // 上传的接口地址
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/TempFileUpLoad",
                discoveryPicturelist: [],
                //图片的请求头信息
                picUploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken(),
                    type: "photo"
                },
                previewVisible: false,
                previewVisible2: false,
                previewImage: '',
                previewImage2: '',
                //发现时图片
                discoveryPicture: undefined,
                discoveryPicturelist: [],
                improvePicture: undefined,
                improvePicturelist: [],
                respName: undefined,
                discoverer: undefined,
                auditPoint: undefined,
                issueTypeListName: undefined,
                issueProgress: undefined,
                discoveryTime: undefined,
                closeTime: undefined,
                planStart: undefined,
                planEnd: undefined,
                description: undefined,
                discoveryPicture: undefined,
                improvePicture: undefined,
                improveNote: undefined,
                correctiveNote: undefined,
                remark: undefined,
                realPath: undefined,
                img: "",
                image: "",
                photos: undefined,
                photo: [],
                Photos: undefined,
                isKanban: false,
                Photo: [],
                poth: undefined,
                issueSubscribeId: undefined,
                number: undefined,
                issueProgressName: undefined,
                history: [],
            }
        },
        methods: {
            //获取编辑数据
            getData() {
                this.isLoading = true;
                this.IssueServiceProxy.getView(this.id)
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res)
                        this.respName = res.respName;
                        this.discoverer = res.discoverer;
                        this.issueTypeListName = res.issueTypeListName;
                        this.auditPoint = res.auditPoint;
                        this.issueProgress = res.issueProgress;
                        this.discoveryTime = res.discoveryTime ? moment(res.discoveryTime).format('YYYY-MM-DD HH:mm') : "";
                        this.closeTime = res.closeTime ? moment(res.closeTime).format('YYYY-MM-DD HH:mm') : "";
                        this.planStart = res.planStart ? moment(res.planStart).format('YYYY-MM-DD') : "";
                        this.planEnd = res.planEnd ? moment(res.planEnd).format('YYYY-MM-DD') : "";
                        this.description = res.description;
                        this.issueSubscribeId = res.issueSubscribeId;
                        this.realPath = res.realPath;
                        this.issueProgressName = res.issueProgressName;
                        this.discoveryPicture = res.discoveryPicture;
                        this.improvePicture = res.improvePicture;
                        this.AuditItemName = res.auditItemName;
                        console.log(res.issueSubscribeId)
                        this.poth = this.realPath + '/';
                        this.photo = [];
                        this.Photo = [];
                        if (res.discoveryPicture == undefined) {

                        } else {
                            // this.img = true;
                            var str1 = res.discoveryPicture.split(',');
                            str1.forEach(item => {
                                this.photos = this.realPath + '/' + item;
                                this.photo.push(this.photos);
                            });
                        }
                        if (res.improvePicture == undefined) {

                        } else {
                            // this.image = true;
                            var str2 = res.improvePicture.split(',');
                            str2.forEach(item => {
                                this.Photos = this.realPath + '/' + item;
                                this.Photo.push(this.Photos);
                            });
                        }

                        this.improveNote = res.improveNote;
                        this.correctiveNote = res.correctiveNote;
                        this.remark = res.remark;
                        if (this.issueSubscribeId == null) {
                            document.getElementById("concern").theme = "";
                            // console.log(document.getElementById("concern"))
                        } else {
                            document.getElementById("concern").theme = "filled";
                            // console.log(document.getElementById("concern"))
                        }

                        this.history = res.history;
                        for (let index = 0; index < this.history.length; index++) {
                            this.history[index].operateTime = this.history[index].operateTime.format('YYYY-MM-DD HH:mm');

                        }

                        this.getConcern();
                    });


            },
			splitPictures(oldimg, newimg) {
				if (!oldimg) {
					oldimg = "";
				}
				if (!newimg) {
					newimg = "";
				}
                if (oldimg.substr(-1) == ",") {
                    oldimg = oldimg.substr(0, oldimg.length - 1);
                }
                if (newimg.substr(-1) == ",") {
                    newimg = newimg.substr(0, newimg.length - 1);
                }
                let oldarr = oldimg.split(",");
                let newarr = newimg.split(",");
                let temparr = [];
                if (oldarr.length > newarr.length) {
                    temparr = oldarr.filter(item1 => !newarr.some(item2 => item2 === item1));
                    temparr.push("上传了");
                } else if (oldarr.length < newarr.length) {
                    temparr = newarr.filter(item1 => !oldarr.some(item2 => item2 === item1));
                    temparr.push("删除了");
                } else if (oldarr.length == newarr.length && oldarr.sort().toString() != newarr.sort().toString()) {
                    temparr = newarr;
                    temparr.push("调整了");
                }
                return temparr;
            },
            show() {
                const vuer = this.$el.querySelector('.images').$vuer
                vuer.show()
            },
            Show() {
                const vuer = this.$el.querySelector('.images1').$vuer
                vuer.show()
            },
            Edit() {
                ModalHelper.create(CreatErrorCode, {
                    id: this.id,
                    isKanban: this.isKanban
                }, { width: 1100 }).subscribe(res => {
                    this.close();
                    if (res) {
                        this.getData();
                    }
                })
            },
            Close() {
                ModalHelper.create(closeissue, {
                    id: this.id
                }, { width: 480 }).subscribe(res => {
                    if (res) {
                        this.getData();
                    }
                })
            },
            //获取关注人数
            getConcern() {
                this.isLoading = true;
                this.IssueServiceProxy.getIssueSubscribeByUsers(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.number = res.length;
                    console.log(this.number)
                    document.getElementById("number").innerHTML = this.number;
                })
            },
            getNumber() {
                ModalHelper.create(ViewNumber, {
                    id: this.id
                }, { width: 600 }).subscribe(res => {
                    // this.close();
                    if (res) {
                        this.getData();
                    }
                })
            },
            concern() {
                this.isLoading = true;
                let issueId = { IssueId: this.id };
                if (this.issueSubscribeId != null) {
                    this.IssueServiceProxy.removeStar(
                        this.id
                    ).finally(() => {
                        this.isLoading = false;
                    }).then(res => {

                    })
                } else {
                    this.IssueServiceProxy.addStar(
                        issueId
                    ).finally(() => {
                        this.isLoading = false;
                    }).then(res => {

                    })
                }
                this.getData();
            },
        }
    }
</script>

<style>
    .problemview {
        text-align: right;
    }

    .body {
        margin-bottom: 10px;
    }

    .prompt {
        height: 117px;
        margin-top: 10px;
    }

    .Image {
        width: 100px;
        height: 100px;
        border: 1px solid #c0c0c0;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
    }
</style>
import axios from 'axios';
import { Modal } from 'ant-design-vue';
import { AppConsts } from '@/abpPro/AppConsts';
import { abpService } from '@/shared/abp';
import { localizationService } from '@/shared/i18n';


const apiHttpClient = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 300000
});

// request interceptor
apiHttpClient.interceptors.request.use(function (config) {
  if (!!abpService.abp.auth.getToken()) {
    config.headers.common['Authorization'] = 'Bearer ' + abpService.abp.auth.getToken() || '';
  }
  config.headers.common['.AspNetCore.Culture'] = abpService.abp.utils.getCookieValue('Abp.Localization.CultureName');
  config.headers.common['Abp.TenantId'] = abpService.abp.multiTenancy.getTenantIdCookie() || '';
  config.headers.common['ScenesId'] = localStorage.getItem('ScenesId');
  return config;

}, function (error) {

  return Promise.reject(error);
});


// response error interceptor
apiHttpClient.interceptors.response.use((response) => {
  if (response.data['__abp']) {
    response.data = response.data.result;
  }
  return response;
}, (error) => {
  console.log(error.response)
  if(error.response.status===401){
    Modal.error({
      title: error.response.data.error.message,
      content: error.response.data.error.details,
      okText:localizationService.l("Ok"),
      onOk:()=>{
        location.href = AppConsts.appBaseUrl;
      }
    });
  }
  else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
    Modal.error({
      title: error.response.data.error.message,
      content: error.response.data.error.details
    });
  } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
    Modal.error({
      okText:localizationService.l("Ok"),
      title: localizationService.l('Abnormal_operation'),
      content: localizationService.l(error.response.data.error.message)
    });
  } else if (!error.response) {
    Modal.error({
      content: localizationService.l('UnknownError')
    });
  }

  return Promise.reject(error);
});

export default apiHttpClient;

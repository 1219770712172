import { RouteConfig } from 'vue-router';
import AccountLayout from '@/account/account-layout.vue';

const accountRouting: RouteConfig[] = [
	{
		path: '/account',
		component: AccountLayout,
		redirect: '/account/login',
		children: [
			{
				path: 'login',
				name: 'login',
				component: () => import(/* webpackChunkName: "account" */ './login/login.vue'),
			},
		],
	},
];

export default accountRouting;

<template>
		<div>
		    <a-config-provider :locale="zh_CN">
				<a-card style="border: 0px;" class="ant-card-body-par">
					<a-spin :spinning="isLoading">
					    <a-table :columns="columns" :data-source="tableData"
					             :rowKey="(record)=>record.id"
								 :scroll="{x:800,y:400}"
					             :pagination="false"
					             size="small"
					    >
							<span slot="auditItemName" slot-scope="text,record">
							    <!-- <a-icon type="check" style="color: green" v-if="record.result == 'Y'" />
								<a-icon type="close" style="color: red" v-if="record.result == 'N'" /> -->
								<img src="../../../../../public/img/icons/Skip.png" style="width: 18px;margin-top: -3px;" :title="l('Skip')" v-if="record.isSkipAuditPoint"/>
								{{text}}
							</span>
                            <span slot="isSkipAuditPoint" slot-scope="text,record">
							    <a-icon type="check" v-if="record.isSkipAuditPoint" />
								<a-icon type="close" v-else />
							</span>
					        <span slot="action" slot-scope="text,record">
					            <a @click.stop="handleEdit(record)">{{l('ViewDetails')}}</a>
					        </span>
					    </a-table>
					</a-spin>
					<a-pagination
					        class="pagination"
					        :total="totalItems"
					        v-model="pageNumber"
					        showSizeChanger
					        showQuickJumper
							:show-total="total => `共有 ${totalItems} 条`"
							:defaultPageSize="request.maxResultCount"
							:pageSizeOptions="pageSizeOptions"
					        @change="onChangePage"
					        @showSizeChange="showSizeChange"
							style="margin-bottom: -10px;"/>
				</a-card>
			</a-config-provider>
		</div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { AuditTaskServiceProxy } from '../../../../shared/service-proxies';
	import { ModalHelper } from '@/shared/helpers';
	import EditThePart from "../check-the-details/check-the-details.vue";
	import moment from "moment";
	
	let _this;
	export default {
		name: 'OrderExcution',
		mixins: [ModalComponentBase],
		props:{
			auditID:[],
		},
		components: {
		    
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
		    this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
		},
		watch:{
		    auditID:function () {
		        this.getData();
		    },
		},
		data() {
		    return {
				zh_CN,
				tableData: [],
				columns: [
				    {title: this.l('220'), width: 150, dataIndex: 'auditPointName', align: 'center',ellipsis: true,},
					{title: this.l('audititem.audititemtypeid'), width: 90, dataIndex: 'auditItemTypeListName', align: 'center',ellipsis: true,},
				    {title: this.l('221'), width: 400, dataIndex: 'auditItemName', align: 'left',ellipsis: true,scopedSlots: {customRender: 'auditItemName'}},
				    {title: this.l('Auditresults'), width: 100, dataIndex: 'result', align: 'center',},
				    {title: this.l('Completetime'), width: 160, dataIndex: 'auditTime', align: 'center',},
					{title: this.l('audit.inspectorname'), width: 120, dataIndex: 'inspectorName', align: 'center',},
				    {title: this.l('IsSkipAuditPoint'), width: 100, dataIndex: 'isSkipAuditPoint', align: 'center',ellipsis: true,scopedSlots: {customRender: 'isSkipAuditPoint'}},
				    {title: this.l('Action'), width: 100, dataIndex: 'action', align: 'center',scopedSlots: {customRender: 'action'},fixed: 'right'},
				],
				
				isLoading: false,//加载中
				filterText: '',//搜索
				totalItems: 0,//总数
				// 当前页码
				pageNumber: 1,
				// 共多少页
				totalPages: 1,
				// 条数显示范围
				pagerange: [1, 1],
				// 显示条数
				pageSizeOptions: ["10", "20", "50", "100", "500"],
				request: {maxResultCount: 20, skipCount: 0},
		        isLook: false,//是否是查看
				isEdit: true,
				
		    }
		},
		methods: {
			getData() {
				console.log(this.auditID);
				this.isLoading = true;
				this.AuditTaskServiceProxy.getDetailByAuditTaskId(
				this.auditID,
				this.filterText ? this.filterText : undefined,
				undefined,
				this.request.maxResultCount,
				this.request.skipCount
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					console.log(res);
					this.tableData = res.items
					this.tableData.map(item => {
					    item.auditTime = item.auditTime ? moment(item.auditTime).format('YYYY-MM-DD HH:mm:ss') : "";
					})
					this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
				})
			},
			handleEdit(record) {
			    ModalHelper.create(EditThePart, {
			        record: record,isView: true
			    },{width: 1000}).subscribe(res => {
			
			    })
			},
			//正常分页展示形式，显示（共有 {0} 条）
			showTotalFun() {
			    // return this.l(
			    //     "GridFooterDisplayText",
			    //     this.totalItems
			    // );
			},
			
			/**
			 * 分页
			 */
			onChangePage(page, pageSize) {
			    this.selectedRowKeys = []
			    this.pageNumber = page;
			    this.request.skipCount = (page - 1) * this.request.maxResultCount;
			    this.getData();
			},
			showSizeChange(current, size) {
			    this.pageNumber = 1;
			    this.request.maxResultCount = size;
			    this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
			    this.getData();
			},
		}
	}
</script>

<style>
	.pagination {
	    margin: 10px auto;
	    text-align: right;
	}
	
	.ant-card-body-par > .ant-card-body {
	    padding: 0px 0px 10px 0px;
	}
</style>

import { render, staticRenderFns } from "./nav-item-icon.vue?vue&type=template&id=510f7a09&scoped=true&functional=true&"
import script from "./nav-item-icon.vue?vue&type=script&lang=js&"
export * from "./nav-item-icon.vue?vue&type=script&lang=js&"
import style0 from "./nav-item-icon.vue?vue&type=style&index=0&id=510f7a09&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "510f7a09",
  null
  
)

export default component.exports
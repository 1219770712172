import Vue from 'vue';
import VueRouter from 'vue-router';
import {accountRouting} from '@/account';

import {appRouting} from '@/app';
import SetPasswordRouting from "@/account/set-password.routing";
import LPA from '../app/kanban/LPA/LPA.vue'

Vue.use(VueRouter);

const rootRooting = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...accountRouting,
        ...SetPasswordRouting,
        ...appRouting,
		{
		  path: '/app/kanban/LPA',
		  name: 'LPA',
		  component: LPA,
		  meta: {
		    title: 'LPA'
		  }
		}
    ]
});
export default rootRooting;

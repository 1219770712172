<template>
    <div class="kanban">
        <div class="header">
			<div class="title1">
				<img src="../../../../public/img/image/logo.png" style="margin-top: 7%;margin-left: 6%;" />
			</div>
			<div class="title2">
				LPA看板
			</div>
			<div class="title3">
				<div style="margin-right: 10%;">
					{{time}}
				</div>
			</div>
		</div>
		<div class="mainstay">
			<div class="row1">
				<div class="subtitle">
					本月统计
				</div>
				<div class="column1">
					<div class="frame layout">
						<div class="icon-outer">
							<div class="icon-inner">
								
							</div>
						</div>
						<div class="frame" id="DepartmentalPlanCompletionRate">
							
						</div>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div class="icon-outer">
							<div class="icon-inner">
								
							</div>
						</div>
						<div class="frame" id="DepartmentalCloseRate">
							
						</div>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div class="icon-outer">
							<div class="icon-inner">
								
							</div>
						</div>
						<div class="frame" id="DepartmentalConformRate">
							
						</div>
					</div>
				</div>
			</div>
			<div class="row2">
				<div class="subtitle">
					
				</div>
				<div class="column2">
					<div class="frame">
						<div style="width: 100%;height: 15%;position: relative;">
							<div class="icon-outer">
								<div class="icon-inner">
									
								</div>
							</div>
							<div style="position: absolute;left: 52px;top: 13px;font-size: 20px;color: #fff">
								审核计划
							</div>
							<div style="position: absolute;right: 45px;top: 13px;font-size: 20px;color: #fff" @click="AuditTaskView()">
								More
								<a-icon type="caret-right" />
							</div>
						</div>
						<div style="width: 100%;height: 80%;">
							<table style="width: 94%;height: 15%;margin-left: 3%;margin-top: 1%;background-color: #253f8c;">
								<tbody>
									<tr style="text-align: center;color: #fff">
										<th style="border: 1px solid #fff;width: 20%;">日期</th>
										<th style="border: 1px solid #fff;width: 60%;">名称</th>
										<th style="border: 1px solid #fff;width: 10%;">审核人</th>
										<th style="border: 1px solid #fff;width: 10%;">状态</th>
									</tr>
								</tbody>
							</table>
							<div class="auditPlan">
								<table style="width: 100%;height: 100%;table-layout: fixed;">
									<tbody :class="{anim:animate==true}">
										<tr style="text-align: center;color: #fff;" v-for="(item,index) in AuditPlanList" @click="AuditPlanView(item)">
											<td style="border: 1px solid #fff;width: 20%;">
												<div style="width: 100%;height: 30.2px;display: flex;flex-direction: column;justify-content: center;">
													{{item.fomartDateTime}}
												</div>
											</td>
											<td style="border: 1px solid #fff;width: 60%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;text-align: left;">{{item.name}}</td>
											<td style="border: 1px solid #fff;width: 10%;">{{item.inspectorName}}</td>
											<td style="border: 1px solid #fff;width: 10%;" :class="{backgroundcolor:item.isDisplay==true}">{{item.auditTaskStateName}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="column2">
					<div class="frame">
						<div class="Level">
							<div v-for="(item,index) in LevelList" style="width: 100%;height: 100%;" :id="'Level' + index" v-if="LevelList.length == 1">
								
							</div>
							<div v-for="(item,index) in LevelList" style="width: 50%;height: 100%;float: left;" :id="'Level' + index" v-if="LevelList.length == 2">
								
							</div>
							<div v-for="(item,index) in LevelList" style="width: 33.3%;height: 100%;float: left;" :id="'Level' + index" v-if="LevelList.length > 2">
								
							</div>
						</div>
					</div>
				</div>
				<div class="column2">
					<div class="frame">
						<div style="width: 100%;height: 50%;">
							<div style="width: 5%;height: 100%;position: relative;float: left;">
								<div class="icon-outer">
									<div class="icon-inner">
										
									</div>
								</div>
								<div style="position: absolute;left: 25px;top: 40px;font-size: 16px;color: #fff;width: 16px;" @click="IssueListView()">
									问题清单
								</div>
								
							</div>
							<div style="width: 95%;height: 100%;float: left;">
								<div style="width: 100%;height: 100%;">
									<table style="width: 94%;height: 18%;margin-left: 3%;margin-top: 2%;background-color: #253f8c;">
										<tbody>
											<tr style="text-align: center;color: #fff">
												<th style="border: 1px solid #fff;width: 13%;">发现日期</th>
												<th style="border: 1px solid #fff;width: 30%;">问题描述</th>
												<th style="border: 1px solid #fff;width: 21%;">类别</th>
												<th style="border: 1px solid #fff;width: 13%;">区域</th>
												<th style="border: 1px solid #fff;width: 13%;">负责人</th>
												<th style="border: 1px solid #fff;width: 10%;">计划完成</th>
											</tr>
										</tbody>
									</table>
									<div class="auditTask">
										<table style="width: 100%;height: 100%;table-layout: fixed;">
											<tbody :class="{anim:animate==true}">
												<tr style="text-align: center;color: #fff;" v-for="(item,index) in AuditTaskList" @click="IssueView(item)">
													<td style="border: 1px solid #fff;width: 13%;">{{item.discoveryTime}}</td>
													<td style="border: 1px solid #fff;width: 30%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;text-align: left;">{{item.description}}</td>
													<td style="border: 1px solid #fff;width: 21%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{item.issueTypeListName}}</td>
													<td style="border: 1px solid #fff;width: 13%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{item.auditPoint}}</td>
													<td style="border: 1px solid #fff;width: 13%;">{{item.respName}}</td>
													<td style="border: 1px solid #fff;width: 10%;" :class="{backgroundcolor:item.isDisplay==true}">{{item.issueProgressName}}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div style="width: 100%;height: 50%;display: flex;">
							<div style="width: 60%;height: 100%;" id="IusseRegion">
								
							</div>
							<div style="width: 40%;height: 100%;" id="IusseClassify">
								
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row3">
				<div class="subtitle">
					年度统计
				</div>
				<div class="column1">
					<div class="frame layout">
						<div class="icon-outer">
							<div class="icon-inner">
								
							</div>
						</div>
						<div class="frame" id="PlanCompletionRate">
							
						</div>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div class="icon-outer">
							<div class="icon-inner">
								
							</div>
						</div>
						<div class="frame" id="CloseRate">
							
						</div>
					</div>
				</div>
				<div class="column1">
					<div class="frame layout">
						<div class="icon-outer">
							<div class="icon-inner">
								
							</div>
						</div>
						<div class="frame" id="ConformRate">
							
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
	import { AppComponentBase } from "@/shared/component-base";
	import { KanBanServiceProxy } from '../../../shared/service-proxies';
	import { ModalHelper } from '@/shared/helpers';
	import AuditPlanView from "../../underlying-data/audit-task-view/audit-task-view.vue";
	import AuditTaskView from "../../underlying-data/audit-task/audit-task.vue";
	import IssueView from "../../underlying-data/problem-management/view/view.vue";
	import IssueListView from "../../underlying-data/problem-management/problem-management.vue";
	import AuditItemIssueStatistics from "../../underlying-data/AuditItemIssueStatistics/AuditItemIssueStatistics.vue";
	import MonthViewEmp from "../../underlying-data/report/month-view-emp/month-view-emp.vue";
	import * as echarts from 'echarts';
	import moment from "moment";
	let _this;
		
	export default {
	    name: "LPA",
	    mixins: [AppComponentBase],
	    components: {
	        AuditPlanView,
			AuditTaskView,
			IssueView,
			IssueListView,
			MonthViewEmp,
			AuditItemIssueStatistics
	    },
	    created() {
	        _this = this;
	        this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
	    },
	    mounted() {
			setInterval(this.NowData, 0);
	        this.getDepartmentalCompletionRate();
			this.getDepartmentalCloseRate();
			this.getDepartmentalConformRate();
			this.getCompletionRate();
			this.getCloseRate();
			this.getConformRate();
			this.getAuditPlan();
			this.getLevel();
			this.getAuditTaskListInput();
			this.getIusseRegion();
			this.getIusseclassify();
	    },
	    data() {
	        return {
				time: undefined,
	            deptPlanCompletionRateList: [],
				departmentalCompletionData: [],
				deptCloseRateList: [],
				departmentalCloseData: [],
				deptConformList: [],
				deptConformData: [],
				planCompletionRateList: [],
				completionData: [],
				closeRateList: [],
				closeData: [],
				conformList: [],
				conformData: [],
				LevelList: [],
				Level: [],
				LevelNumber: undefined,
				LevelData: [],
				IssueTypeData: [],
				IssuePointName: [],
				IssuePointData: [],
				AuditTaskList: [],
				EndAuditTaskId: undefined,
				AuditTaskTimer: undefined,
				animate: false,
				AuditPlanList: [],
				EndAuditPlanId: undefined,
				AuditPlanTimer: undefined,
				AuditPlanAnimate: false,
				LevelAnimate: false
	        }
	    },
		computed: {
			option1() {
				return {
					title: {
					    text: '部门计划完成率',
						y: '5%',
						x: '10%',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					legend: {
						right: '5%',
						top: '5%',
						textStyle: {
							color: '#fff'
						}
					},
					// calculable: true,
					xAxis: [{
						type: 'category',
						data: this.deptPlanCompletionRateList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							lineStyle: {
							  color: '#fff'
							},
							color: '#fff',
							fontSize: 10,
							formatter: function(params) {
								let name = '';
								let number = params.length;
								let provideNumber = 4;
								if(_this.deptPlanCompletionRateList.length >8) {
									provideNumber = 2;
								}else {
									provideNumber = 3;
								}
								let rowNumber = Math.ceil(number / provideNumber);
								if(number > provideNumber) {
									for (let p = 0; p < 2; p++) {
										let tempStr;
										let start = p * provideNumber;
										let end = start + provideNumber;
										if (p == rowNumber - 1) {
											tempStr = params.substring(start, number);
										} else {
											tempStr = params.substring(start, end) + "\n";
										}
										name += tempStr;
									}
								}else {
									name = params
								}
								return name
							}
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('完成率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.departmentalCompletionData,
				}
			},
			option2() {
				return {
					title: {
					    text: '部门问题关闭率',
						y: '5%',
						x: '10%',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					legend: {
						right: '5%',
						top: '5%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.deptCloseRateList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff',
							fontSize: 10,
							formatter: function(params) {
								let name = '';
								let number = params.length;
								let provideNumber = 4;
								if(_this.deptCloseRateList.length >8) {
									provideNumber = 2;
								}else {
									provideNumber = 3;
								}
								let rowNumber = Math.ceil(number / provideNumber);
								if(number > provideNumber) {
									for (let p = 0; p < 2; p++) {
										let tempStr;
										let start = p * provideNumber;
										let end = start + provideNumber;
										if (p == rowNumber - 1) {
											tempStr = params.substring(start, number);
										} else {
											tempStr = params.substring(start, end) + "\n";
										}
										name += tempStr;
									}
								}else {
									name = params
								}
								return name
							}
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('关闭率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.departmentalCloseData,
				}
			},
			option3() {
				return {
					title: {
					    text: '部门符合率',
						y: '5%',
						x: '10%',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					legend: {
						right: '5%',
						top: '5%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.deptConformList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff',
							fontSize: 10,
							formatter: function(params) {
								let name = '';
								let number = params.length;
								let provideNumber = 4;
								if(_this.deptConformList.length >8) {
									provideNumber = 2;
								}else {
									provideNumber = 3;
								}
								let rowNumber = Math.ceil(number / provideNumber);
								if(number > provideNumber) {
									for (let p = 0; p < 2; p++) {
										let tempStr;
										let start = p * provideNumber;
										let end = start + provideNumber;
										if (p == rowNumber - 1) {
											tempStr = params.substring(start, number);
										} else {
											tempStr = params.substring(start, end) + "\n";
										}
										name += tempStr;
									}
								}else {
									name = params
								}
								return name
							}
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('符合率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.deptConformData,
				}
			},
			option4() {
				return {
					title: {
					    text: '计划完成率',
						y: '5%',
						x: '10%',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					legend: {
						right: '5%',
						top: '5%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.planCompletionRateList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('完成率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.completionData,
				}
			},
			option5() {
				return {
					title: {
					    text: '问题关闭率',
						y: '5%',
						x: '10%',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					legend: {
						right: '5%',
						top: '5%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.closeRateList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('关闭率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.closeData,
				}
			},
			option6() {
				return {
					title: {
					    text: '符合率',
						y: '5%',
						x: '10%',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '35'
					},
					legend: {
						right: '5%',
						top: '5%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.conformList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('符合率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.conformData,
				}
			},
			option8() {
				return {
					title: {
					  text: "问\n题\n区\n域\nT\nO\nP\n3",
					  left: '5%',
					  top: '10%',
					  textStyle: {
						fontSize: 14,
						fontWeight: 'normal',
						color:'#fff'
					  },
					  textAlign: 'center'
					},
					tooltip: {
					  trigger: 'axis',
					  axisPointer: {
					    type: 'shadow',
					  },
					},
					grid: {
					  top: 20,
					  left: '20%',
					  bottom: 15,
					  containLabel: true,
					},
					legend: {
						orient: 'vertical',
						top: '50'
					},
					calculable: true,
					xAxis: {
					  position: 'top',
					  minInterval: 1,
					  type: 'value',
					  boundaryGap: [0, 0.01],
					  axisLabel:{ 
						  show: false ,
					  },
					  splitLine:{
					      show: false
					  },
					},
					yAxis: {
					  type: 'category',
					  data: this.IssuePointName,
					  inverse: true,
					  axisLabel:{
					  	  color: '#fff'
					  },
					  axisTick:{
					      show:false
					  },
					  axisLine: {
					      show: false
					  }
					},
					series: [
					  {
					    // name: this.l('FeedbackIssueNum'),
					    type: 'bar',
					    barMaxWidth: 50, //最大宽度
					    data: this.IssuePointData,
						label: {
						    show: true,
						},
					  },
					],
				}
			},
			option9() {
				return {
					title: {
					  text: "问\n题\n分\n类\nT\nO\nP\n3",
					  left: '5%',
					  top: '10%',
					  textStyle: {
						fontSize: 14,
						fontWeight: 'normal',
						color:'#fff'
					  },
					  textAlign: 'center'
					},
					series: [
					  {
						type: 'pie',
						center: ['50%', '54%'],
						radius: '65%',
						data: this.IssueTypeData,
						label: {
							color: '#fff',
							textBorderWidth: '0'
						}
					  }
					]
				}
			}
		},
	    methods: {
			//时间
			NowData() {
			  let aData = new Date();
			  let year = aData.getFullYear();
			  let month = (aData.getMonth() + 1);
			  let day = aData.getDate();
			  let Hour = aData.getHours();
			  let Minute = aData.getMinutes();
			  let Second = aData.getSeconds();
			  month = month < 10 ? '0' + month : month;
			  day = day < 10 ? '0' + day : day;
			  Hour = Hour < 10 ? '0' + Hour : Hour;
			  Minute = Minute < 10 ? '0' + Minute : Minute;
			  Second = Second < 10 ? '0' + Second : Second;
			  this.time = year + "-" + month + "-" + day + " " + Hour + ":" + Minute + ":" + Second;
			},
			
			//部门计划完成率
	        getDepartmentalCompletionRate() {
	            this.KanBanServiceProxy.getDeptCompletionRate(
					
	            ).finally(() => {
	                
	            }).then(res => {
					if(res.deptList.length <3) {
						for(let i=res.deptList.length;i<3;i++) {
							res.deptList.push('');
						}
					}
					
					if(res.finshList.length <3) {
						for(let i=res.finshList.length;i<3;i++) {
							res.finshList.push(0);
						}
					}
					
					if(res.noFinshList.length <3) {
						for(let i=res.noFinshList.length;i<3;i++) {
							res.noFinshList.push(0);
						}
					}
					
					if(res.finshRateList.length <3) {
						for(let i=res.finshRateList.length;i<3;i++) {
							res.finshRateList.push('');
						}
					}
					let name = [];
					let completedNumber = [];
					let NoCompletionNumber = [];
					for(let i in res.deptList) {
						name.push(res.deptList[i].name);
						let comp = {
							value: res.finshList[i],
							id: res.deptList[i].id
						};
						let NoComp = {
							value: res.noFinshList[i],
							id: res.deptList[i].id
						};
						completedNumber.push(comp);
						NoCompletionNumber.push(NoComp);
					}
					
					this.deptPlanCompletionRateList = name;
					let completed = {
						name: "完成",
						type: 'bar',
						stack: 'bar',
						data: completedNumber,
						barMaxWidth: 20
					};
					
					let NoCompletion = {
						name: "未完成",
						type: 'bar',
						stack: 'bar',
						data: NoCompletionNumber,
						barMaxWidth: 20
					};
					
					let completedRate = {
						name: "完成率",
						type: 'line',
						yAxisIndex : 1,
						data: res.finshRateList,
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					
					this.departmentalCompletionData = [];
					this.departmentalCompletionData.push(completed);
					this.departmentalCompletionData.push(NoCompletion);
					this.departmentalCompletionData.push(completedRate);
					
					this.DepartmentalCompletionRateCharts();
	            })
	        },
			
			DepartmentalCompletionRateCharts() {
				this.myeChart = echarts.init(document.getElementById("DepartmentalPlanCompletionRate"));
			
				this.myeChart.off('click');
				this.myeChart.clear();
				
				this.myeChart.setOption(this.option1);
				
				this.myeChart.on('click', function (params) {
				    let id = params.data.id;
				    ModalHelper.create(MonthViewEmp, {
				        id: id
				    }, { width: 1600 }).subscribe(res => {
				        
				    })
				});
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//部门问题关闭率
			getDepartmentalCloseRate() {
			    this.KanBanServiceProxy.getDeptIssueCloseRate(
					
			    ).finally(() => {
			        
			    }).then(res => {
					if(res.deptList.length <3) {
						for(let i=res.deptList.length;i<3;i++) {
							res.deptList.push('');
						}
					}
					
					if(res.closeList.length <3) {
						for(let i=res.closeList.length;i<3;i++) {
							res.closeList.push(0);
						}
					}
					
					if(res.noCloseList.length <3) {
						for(let i=res.noCloseList.length;i<3;i++) {
							res.noCloseList.push(0);
						}
					}
					
					if(res.closeRateList.length <3) {
						for(let i=res.closeRateList.length;i<3;i++) {
							res.closeRateList.push('');
						}
					}
					
					let name = [];
					let closeNumber = [];
					let noCloseNumber = [];
					for(let i in res.deptList) {
						name.push(res.deptList[i].name);
						let close = {
							value: res.closeList[i],
							id: res.deptList[i].id
						};
						let noClose = {
							value: res.noCloseList[i],
							id: res.deptList[i].id
						};
						closeNumber.push(close);
						noCloseNumber.push(noClose);
					}
					
					this.deptCloseRateList = name;
					
					let closeList = {
						name: "关闭",
						type: 'bar',
						stack: 'bar',
						data: closeNumber,
						barMaxWidth: 20
					};
					
					let noCloseList = {
						name: "未关闭",
						type: 'bar',
						stack: 'bar',
						data: noCloseNumber,
						barMaxWidth: 20
					};
					
					let closeRateList = {
						name: "关闭率",
						type: 'line',
						yAxisIndex : 1,
						data: res.closeRateList,
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					
					this.departmentalCloseData = [];
					this.departmentalCloseData.push(closeList);
					this.departmentalCloseData.push(noCloseList);
					this.departmentalCloseData.push(closeRateList);
					
					this.DepartmentalCloseRateCharts();
			    })
			},
			
			DepartmentalCloseRateCharts() {
				this.myeChart = echarts.init(document.getElementById("DepartmentalCloseRate"));
			
				this.myeChart.off('click');
				this.myeChart.clear();
				
				this.myeChart.setOption(this.option2);
				
				this.myeChart.on('click', function (params) {
				    let id = params.data.id;
				    ModalHelper.create(IssueListView, {
				        month: 1
				    }, { width: 1600 }).subscribe(res => {
				        
				    })
				});
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//部门符合率
			getDepartmentalConformRate() {
			    this.KanBanServiceProxy.getDeptComplianceRate(
					
			    ).finally(() => {
			        
			    }).then(res => {
					if(res.deptList.length <3) {
						for(let i=res.deptList.length;i<3;i++) {
							res.deptList.push('');
						}
					}
					
					if(res.issueList.length <3) {
						for(let i=res.issueList.length;i<3;i++) {
							res.issueList.push(0);
						}
					}
					if(res.complianceRateList.length <3) {
						for(let i=res.complianceRateList.length;i<3;i++) {
							res.complianceRateList.push('');
						}
					}
					
					let name = [];
					let completedNumber = [];
					for(let i in res.deptList) {
						name.push(res.deptList[i].name);
						let comp = {
							value: res.issueList[i],
							id: res.deptList[i].id
						};
						completedNumber.push(comp);
					}
					this.deptConformList = name;
					let issueList = {
						name: "问题",
						type: 'bar',
						stack: 'bar',
						data: completedNumber,
						barMaxWidth: 20
					};
					
					let complianceRateList = {
						name: "符合率",
						type: 'line',
						yAxisIndex : 1,
						data: res.complianceRateList,
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					
					this.deptConformData = [];
					this.deptConformData.push(issueList);
					this.deptConformData.push(complianceRateList);
					
					this.DepartmentalConformRateCharts();
			    })
			},
			
			DepartmentalConformRateCharts() {
				this.myeChart = echarts.init(document.getElementById("DepartmentalConformRate"));
			
				this.myeChart.off('click');
				this.myeChart.clear();
				
				this.myeChart.setOption(this.option3);
				
				this.myeChart.on('click', function (params) {
				    let id = "";
				    // params.data.tasklist.forEach(item => {
				    //     id = item.id
				    // });
				    ModalHelper.create(AuditItemIssueStatistics, {
				        
				    }, { width: 1600 }).subscribe(res => {
				        
				    })
				});
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//计划完成率
			getCompletionRate() {
			    this.KanBanServiceProxy.getYearCompletionRate(
					
			    ).finally(() => {
			        
			    }).then(res => {.0
					this.planCompletionRateList = res.monthList;
					let completed = {
						name: "完成",
						type: 'bar',
						stack: 'bar',
						data: res.finshList,
						barMaxWidth: 20
					};
					
					let NoCompletion = {
						name: "未完成",
						type: 'bar',
						stack: 'bar',
						data: res.noFinshList,
						barMaxWidth: 20
					};
					
					let completedRate = {
						name: "完成率",
						type: 'line',
						yAxisIndex : 1,
						data: res.finshRateList,
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					
					this.completionData = [];
					this.completionData.push(completed);
					this.completionData.push(NoCompletion);
					this.completionData.push(completedRate);
					
					this.CompletionRateCharts();
			    })
			},
			
			CompletionRateCharts() {
				this.myeChart = echarts.init(document.getElementById("PlanCompletionRate"));
				
				this.myeChart.off('click');
				this.myeChart.clear();
				
				this.myeChart.setOption(this.option4);
				
				this.myeChart.on('click', function (params) {
					let date = moment(new Date()).format('YYYY') + '-' + params.name;
				    ModalHelper.create(MonthViewEmp, {
				        kanbanDate: date
				    }, { width: 1600 }).subscribe(res => {
				        
				    })
				});
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//问题关闭率
			getCloseRate() {
			    this.KanBanServiceProxy.getYearIssueCloseRate(
					
			    ).finally(() => {
			        
			    }).then(res => {
					this.closeRateList = res.monthList;
					let closeList = {
						name: "关闭",
						type: 'bar',
						stack: 'bar',
						data: res.closeList,
						barMaxWidth: 20
					};
					
					let noCloseList = {
						name: "未关闭",
						type: 'bar',
						stack: 'bar',
						data: res.noCloseList,
						barMaxWidth: 20
					};
					
					let closeRateList = {
						name: "关闭率",
						type: 'line',
						yAxisIndex : 1,
						data: res.closeRateList,
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					
					this.closeData = [];
					this.closeData.push(closeList);
					this.closeData.push(noCloseList);
					this.closeData.push(closeRateList);
					
					this.CloseRateCharts();
			    })
			},
			
			CloseRateCharts() {
				this.myeChart = echarts.init(document.getElementById("CloseRate"));
				
				this.myeChart.off('click');
				this.myeChart.clear();
				
				this.myeChart.setOption(this.option5);
				
				this.myeChart.on('click', function (params) {
				    let year = moment(new Date()).format('YYYY');
					let startTime = new Date(year, params.name -1,1);
					let endTime = new Date(startTime.getFullYear(), startTime.getMonth() + 1,0);
				    ModalHelper.create(IssueListView, {
				        startTime: startTime,
						endTime: endTime
				    }, { width: 1600 }).subscribe(res => {
				        
				    })
				});
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//符合率
			getConformRate() {
			    this.KanBanServiceProxy.getYearComplianceRate(
					
			    ).finally(() => {
			        
			    }).then(res => {
					this.conformList = res.monthList;
					let issueList = {
						name: "问题",
						type: 'bar',
						stack: 'bar',
						data: res.issueList,
						barMaxWidth: 20
					};
					
					let complianceRateList = {
						name: "符合率",
						type: 'line',
						yAxisIndex : 1,
						data: res.complianceRateList,
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					
					this.conformData = [];
					this.conformData.push(issueList);
					this.conformData.push(complianceRateList);
					
					this.ConformRateCharts();
			    })
			},
			
			ConformRateCharts() {
				this.myeChart = echarts.init(document.getElementById("ConformRate"));
			
				this.myeChart.off('click');
				this.myeChart.clear();
				
				this.myeChart.setOption(this.option6);
				
				this.myeChart.on('click', function (params) {
				    let year = moment(new Date()).format('YYYY');
					let startTime = new Date(year, params.name -1,1);
					let endTime = new Date(startTime.getFullYear(), startTime.getMonth() + 1,0);
				    ModalHelper.create(AuditItemIssueStatistics, {
				        startTime: startTime,
				        endTime: endTime
				    }, { width: 1600 }).subscribe(res => {
				        
				    })
				});
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//审核计划
			getAuditPlan() {
				let date = new Date();
				this.KanBanServiceProxy.getAuditTaskList(
					undefined,
					undefined,
					undefined,
					undefined,
					date
				).finally(() => {
				    
				}).then(res => {
					this.AuditPlanList = res;
					
					// this.AuditPlanList.map(item => {
					// 	item.isDisplay = false;
					// 	if(item.issueProgressName=='未完成') {
					// 		if(item.planEnd) {
					// 			let nowDate = new Date();
					// 			if(Date.parse(item.planEnd) < Date.parse(nowDate)) {
					// 				item.isDisplay = true;
					// 			}
					// 		}
					// 	}
					// });
					if(this.AuditPlanList.length != 0) {
						this.EndAuditPlanId = this.AuditPlanList[this.AuditPlanList.length - 1].id;
					}
					
					if (this.AuditPlanList.length > 5) {
						this.AuditPlanTimer = setInterval(this.AuditPlanScroll, 5000);
					} else if (this.AuditPlanList.length  == 5) {
						clearInterval(this.AuditPlanTimer)
					} else if (this.AuditPlanList.length < 5) {
						for(let i=this.AuditPlanList.length;i<5;i++) {
							let data = {
								fomartDateTime : '',
								name : '',
								inspectorName : '',
								auditTaskStateName : '',
								isDisplay: false
							};
							this.AuditPlanList.push(data);
						}
						clearInterval(this.AuditPlanTimer)
					}
				})
			},
			
			AuditPlanScroll() {
				this.AuditPlanAnimate = true;
				setTimeout(() => {
					this.AuditPlanList.push(this.AuditPlanList[0]);
					this.AuditPlanList.shift();
					this.AuditPlanAnimate = false;
					if (this.AuditPlanList[0].id === this.EndAuditPlanId) {
						this.getAuditPlan();
						clearInterval(this.AuditPlanTimer)
					}
				}, 500);
			},
			
			//层级
			getLevel() {
				this.KanBanServiceProxy.getUserLevel(
					
				).finally(() => {
				    
				}).then(res => {
					this.LevelList = res;
					
					// setTimeout(this.LevelCharts(), 3000)
					this.LevelCharts();
				})
			},
			
			LevelCharts() {
				for(let i in this.LevelList) {
					let Level;
					if(this.LevelList[i].levelName.length > 10) {
						Level = this.LevelList[i].levelName.substring(0, 10);
						if(this.LevelList[i].levelName.length > 20) {
							Level += '\n' + this.LevelList[i].levelName.substring(10, 18) + '...';
						}else {
							Level += '\n' + this.LevelList[i].levelName.substring(10, 20);
						}
					}else {
						Level = this.LevelList[i].levelName;
					}
					let LevelNumber = this.LevelList[i].planCount;
					let LevelData = [
					  {value: this.LevelList[i].finshRate, name: this.LevelList[i].TypeName},
					  {value: 100 - this.LevelList[i].finshRate, name: 'NotReached'}
					];
					
					this.$nextTick(() => {
					    this.myeChart = echarts.init(document.getElementById("Level" + i));
					    			
					    this.myeChart.clear();
					    
					    this.myeChart.setOption(
							{
								title: {
								  text: Level,
								  left: 'center',
								  top: '38%',
								  textStyle: {
									// fontSize: this.echartsTitle,
									fontWeight: 'normal',
									color:'#fff'
								  },
								  subtext: '进度：' + LevelData[0].value + '%\n总计划：' + LevelNumber,
								  subtextStyle: {
									  color:'#fff',
									  fontSize: 14,
									  lineHeight: 18
								  }
								},
								color: ['#60c34f', '#f6f6f6'],
								series: [
								  {
									type: 'pie',
									center: ['50%', '50%'],
									radius: ['60%', '75%'],
									avoidLabelOverlap: false,
									hoverAnimation: false,
									label: {
									  show: false,
									  position: 'center'
									},
									labelLine: {
									   show: false
									},
									data: LevelData
								  }
								],
							}
						);
					    
					    this.myeChart.resize();
					    window.addEventListener('resize', () => {
					    	this.myeChart.resize();
					    })
					})
				}
				
				if(this.LevelList.length > 3) {
					this.LevelScroll(0);
				}
			},
			
			LevelScroll(index) {
				this.$nextTick(() => {
					if(index == 0) {
						for(let i in this.LevelList) {
							document.getElementById("Level" + i).style.marginLeft = '0%';
						}
					}else {
						document.getElementById("Level" + (index -1)).style.marginLeft = '-' + 33.3 * index + '%';
					}
					
					let number = index + 1;
					setTimeout(() => {
						if (number == (this.LevelList.length - 2)) {
							console.log(number)
							this.LevelScroll(0);
						}else {
							this.LevelScroll(number);
						}
					}, 5000);
				})
			},
			
			//问题清单
			getAuditTaskListInput() {
				this.KanBanServiceProxy.getIssueList(
					
				).finally(() => {
				    
				}).then(res => {
					this.AuditTaskList = res;
					
					this.AuditTaskList.map(item => {
						item.discoveryTime = item.discoveryTime ? moment(item.discoveryTime).format(
							'YYYY-MM-DD') : "";
							item.isDisplay = false;
						if(item.issueProgressName=='未完成') {
							if(item.planEnd) {
								let nowDate = new Date();
								if(Date.parse(item.planEnd) < Date.parse(nowDate)) {
									item.isDisplay = true;
								}
							}
						}
					});
					if(this.AuditTaskList.length != 0) {
						this.EndAuditTaskId = this.AuditTaskList[this.AuditTaskList.length - 1].id;
					}
					
					if (this.AuditTaskList.length > 2) {
						this.AuditTaskTimer = setInterval(this.AuditTaskScroll, 5000);
					} else if (this.AuditTaskList.length  == 2) {
						clearInterval(this.AuditTaskTimer)
					} else if (this.AuditTaskList.length < 2) {
						for(let i=this.AuditTaskList.length;i<2;i++) {
							let data = {
								discoveryTime : '',
								description : '',
								issueTypeListName : '',
								auditPoint : '',
								respName : '',
								issueProgressName : '',
								isDisplay: false
							};
							this.AuditTaskList.push(data);
						}
						clearInterval(this.AuditTaskTimer)
					}
				})
			},
			
			AuditTaskScroll() {
				this.animate = true;
				setTimeout(() => {
					this.AuditTaskList.push(this.AuditTaskList[0]);
					this.AuditTaskList.shift();
					this.animate = false;
					if (this.AuditTaskList[0].id === this.EndAuditTaskId) {
						this.getAuditTaskListInput();
						clearInterval(this.AuditTaskTimer)
					}
				}, 500);
			},
			
			//问题区域TOP3
			getIusseRegion() {
				let date = new Date();
				this.KanBanServiceProxy.getIssuePointTopThree(
					date
				).finally(() => {
				    
				}).then(res => {
					this.IssuePointName = [];
					this.IssuePointData = [];
					
					if(this.IssuePointName.length > 8) {
						
					}
					
					for(let i in res) {
						this.IssuePointName.push(res[i].name);
						this.IssuePointData.push(res[i].value);
					}
					
					this.IusseRegionCharts();
				})
			},
			
			IusseRegionCharts() {
				this.myeChart = echarts.init(document.getElementById("IusseRegion"));
							
				this.myeChart.clear();
				
				this.myeChart.setOption(this.option8);
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//问题分类TOP3
			getIusseclassify() {
				let date = new Date();
				this.KanBanServiceProxy.getIssueTypeTopThree(
					date
				).finally(() => {
				    
				}).then(res => {
					this.IssueTypeData = res;
					
					this.IusseclassifyCharts();
				})
			},
			
			IusseclassifyCharts() {
				this.myeChart = echarts.init(document.getElementById("IusseClassify"));
							
				this.myeChart.clear();
				
				this.myeChart.setOption(this.option9);
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//审核计划查看
			AuditPlanView(record) {
			    ModalHelper.create(AuditPlanView, {
			        id: record.id
			    }, { width: 1000 }).subscribe(res => {
			        
			    })
			},
			
			//审核任务列表
			AuditTaskView(record) {
			    ModalHelper.create(AuditTaskView, {
			        
			    }, { width: 1600 }).subscribe(res => {
			        
			    })
			},
			
			//问题查看
			IssueView(record) {
			    ModalHelper.create(IssueView, {
			        id: record.id
			    }, { width: 1000 }).subscribe(res => {
			        
			    })
			},
			
			//问题列表
			IssueListView(record) {
			    ModalHelper.create(IssueListView, {
			        
			    }, { width: 1600 }).subscribe(res => {
			        
			    })
			},
	    }
	}
</script>

<style>
	.kanban{
		width: 1920px;
		height: 1080px;
		background-image: url(../../../../public/img/image/bg.png);
	}
	
	.header{
		width: 100%;
		height: 12%;
		display: flex;
	}
	
	.title1{
		width: 30%;
		height: 100%;
	}
	
	.title2{
		width: 40%;
		height: 100%;
		color: #fff;
		font-size: 65px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	
	.title3{
		width: 30%;
		height: 100%;
		color: #fff;
		font-size: 25px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: right;
	}
	
	.mainstay{
		width: 100%;
		height: 88%;
		display: flex;
	}
	
	.row1{
		width: 23.8%;
		height: 100%;
		margin-left: 2%;
	}
	
	.row2{
		width: 46.5%;
		height: 100%;
		margin-left: 1%;
		margin-right: 1%;
	}
	
	.row3{
		width: 23.8%;
		height: 100%;
		margin-right: 2%;
	}
	
	.subtitle{
		width: 100%;
		height: 5%;
		color: #fff;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	.column1{
		width: 100%;
		height: 31%;
		margin-bottom: 1%;
		background-image: url(../../../../public/img/image/frame1.png);
	}
	
	.column2{
		width: 100%;
		height: 31%;
		margin-bottom: 0.5%;
		background-image: url(../../../../public/img/image/frame2.png);
	}
	
	.frame{
		width: 100%;
		height: 100%;
	}
	
	.layout{
		width: 100%;
		height: 100%;
		position: relative;
	}
	
	.icon-outer {
		width: 18px;
		height: 18px;
		border-radius: 9px;
		border: 1px solid #42dfb7;
		position: absolute;
		left: 25px;top: 20px;
	}
	
	.icon-inner {
		width: 12px;
		height: 12px;
		border-radius: 6px;
		background-color: #42dfb7;
		top: 1.5px;left: 1.5px;
		position: absolute;
	}
	
	.anim {
	   transition: all 0.5s;
	   margin-top: -32px;
	}
	
	.auditTask {
		width: 94%;
		height: 75.5%;
		margin-left: 3%;
		overflow-y:auto;
		scrollbar-width: none; /* Firefox 64 */
	}
	
	.auditPlan {
		width: 94%;
		height: 80%;
		margin-left: 3%;
		overflow-y:auto;
		scrollbar-width: none; /* Firefox 64 */
	}
	
	.Level {
		width: 100%;
		height: 100%;
		overflow: hidden;
		white-space: nowrap;
	}
	
	.LevelAnim {
	   transition: all 0.5s;
	   margin-left: -32px;
	}
	
	.auditTask::-webkit-scrollbar {
	      display: none;
	}
	
	.auditPlan::-webkit-scrollbar {
	      display: none;
	}
	
	/* .Level::-webkit-scrollbar {
	      display: none;
	} */
	
	.backgroundcolor {
		color: #ffff00;
		background-color: #e957ee;
	}
</style>

import { RouteConfig } from 'vue-router';
import { LayoutDefault } from '@/layout';
import mainRouting from '@/app/main/main.routing';
import { adminRouting } from './admin';
import {organizationRouting} from "./organization";
import commonbllRouting from "@/app/commonbll/commonbll.routing";
import {processappRouting} from "@/app/processapp";
import AppManageRouting from "@/app/appmanage/appmanage.routing";
import OARouting from "@/app/oa/oa.routing";
import hrRouting from "@/app/hr/hr.routing";
import AttendanceRouting from "@/app/attendance/attendance.routing";
import CustomizeReportRouting from "@/app/customizereport/customizereport.routing";
import {SalaryRouting} from "@/app/salary";
import MessageManageRouting from "@/app/message-manage/message-manage.routing";
import TaskSystemRouting from "@/app/task-system/task-system.routing";
import LaborcostRouting from "@/app/laborcost/laborcost.routing";
import UnderlyingRouting from "@/app/underlying-data/underlying.routing";
import systemadministrationRouting from "@/app/systemadministration/systemadministration.routing";
import ReportRouting from "@/app/report/report.routing";

const appRouting: RouteConfig[] = [
    {
        path: '/app',
        component: LayoutDefault,
        redirect: '/app/main',
        children: [
            ...mainRouting,
            ...adminRouting,
            ...organizationRouting,
            ...commonbllRouting,
            ...processappRouting,
            ...AppManageRouting,
            ...OARouting,
            ...hrRouting,
            ...AttendanceRouting,
            ...CustomizeReportRouting,
            ...SalaryRouting,
            ...MessageManageRouting,
            ...TaskSystemRouting,
            ...LaborcostRouting,
			...UnderlyingRouting,
			...systemadministrationRouting,
			...ReportRouting
        ]
    },
];

export default appRouting;
